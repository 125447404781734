import React from 'react';
import { useNavigate } from 'react-router-dom';

interface SalesReportSearchResultProps {
  data: SalesReportResponse | null;
}

const SalesReportSearchResult = ({ data }: SalesReportSearchResultProps) => {
  const navigate = useNavigate();
  const navigateListPage = () => {
    navigate('/vehicles/delay', {
      state: {
        notAssuranceCount: data?.delayedDirectProductSalesCount,
        assuranceCount: data?.delayedAssuranceProductSalesCount,
      },
    });
  };
  return (
    <div className="py-2 px-4">
      <h1 className="font-bold text-lg mb-4">영업지표</h1>
      <div className="flex justify-between gap-4 mb-4">
        <div className="p-2 border border-gray-300 rounded-lg p-2 flex-1 mr-1">
          <h2 className="font-bold">총 판매중 대수</h2>
          <p className="text-2xl font-bold">{data?.totalSalesProductCount}</p>
          <p>
            직거래 {data?.normalSalesProductCount}대 판매대행 {data?.consignmentSalesProductCount}대 직매입{' '}
            {data?.assuranceSalesProductCount}대 타사딜러 {data?.thirdPartyDealerSalesProductCount}대
          </p>
        </div>
        <div className="p-2 border border-gray-300 rounded-lg flex-1">
          <h2 className="font-bold">총 신규 등록</h2>
          <p className="text-2xl font-bold">{data?.totalProductCount}</p>
          <p>
            직거래 {data?.normalProductCount}대 판매대행 {data?.consignmentProductCount}대 직매입{' '}
            {data?.assuranceProductCount}대 타사딜러 {data?.thirdPartyDealerProductCount}대
          </p>
        </div>
      </div>
      <div className="flex gap-4 justify-between mb-4">
        <div className="p-2 border border-gray-300 rounded-lg flex-1 mr-1">
          <h2 className="font-bold">매입 견적</h2>
          <p>
            신규 요청: <span className="text-lg font-bold">{data?.speedRequestCount}</span>
          </p>
          <p>
            요청중: <span className="text-lg font-bold">{data?.totalSpeedRequestCount}</span>
          </p>
        </div>
        <div className="p-2 border border-gray-300 rounded-lg flex-1 mr-1">
          <h2 className="font-bold">번호판 신규 요청</h2>
          <p className="text-lg font-bold">{data?.licenseAndInquiryTotalCount}</p>
          <p>
            구매대행요청 {data?.licenseInquiryRequestCount}건 판매대행요청 {data?.licenseRequestCount}건
          </p>
        </div>
        <div className="p-2 border border-gray-300 rounded-lg flex-1 ">
          <h2 className="font-bold">대행서비스 신규 요청</h2>
          <p className="text-lg font-bold">{data?.transferAgencyAndOneStopRequestCount}</p>
          <p>
            구매대행요청 {data?.transferAgencyCount}건 판매대행요청 {data?.oneStopCount}건
          </p>
        </div>
      </div>

      <div className="p-2 border border-gray-300 rounded-lg mb-4 mr-[500px]">
        <h2 className="font-bold">평균 판매 기간</h2>
        <div className="flex justify-between">
          <div className="flex-1 mr-2">
            <h3 className="font-bold">직거래</h3>
            <p>
              소형: <span className="text-lg font-bold">{data?.smallDirectProductSalesAverageDay}</span>
            </p>
            <p>
              중형: <span className="text-lg font-bold">{data?.middleDirectProductSalesAverageDay}</span>
            </p>
            <p>
              대형: <span className="text-lg font-bold">{data?.bigDirectProductSalesAverageDay}</span>
            </p>
          </div>
          <div className="flex-1">
            <h3 className="font-bold">직매입</h3>
            <p>
              소형: <span className="text-lg font-bold">{data?.smallAssuranceProductSalesAverageDay}</span>
            </p>
            <p>
              중형: <span className="text-lg font-bold">{data?.middleAssuranceProductSalesAverageDay}</span>
            </p>
            <p>
              대형: <span className="text-lg font-bold">{data?.bigAssuranceProductSalesAverageDay}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="p-2 border border-gray-300 rounded-lg mr-[1000px]" onClick={navigateListPage}>
        <h2 className="font-bold">판매 지연</h2>
        <p>
          직거래: <span className="text-lg font-bold">{data?.delayedDirectProductSalesCount}</span>
        </p>
        <p>
          직매입: <span className="text-lg font-bold">{data?.delayedAssuranceProductSalesCount}</span>
        </p>
      </div>
    </div>
  );
};

export default SalesReportSearchResult;
