import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;
interface SearchProps {
  selectedFilterValue: DashboardSelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<DashboardSelectedFilterValue>>;
  getAppDownloadCountData: () => void;
}

const AppDownloadCountFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getAppDownloadCountData,
}: SearchProps) => {
  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      startDate: '',
      endDate: '',
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex justify-between">
        <div className="">
          <span className="mr-2">기준일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>
        <div>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            className="bg-[#1890ff] mx-2"
            onClick={getAppDownloadCountData}
          >
            검색
          </Button>
          <Button onClick={clear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

export default AppDownloadCountFilterSelector;
