import { deleteProduct, patchLicenseInquiry, patchLicenseInquiryComment } from '@/api/public';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import React, { useEffect, useState } from 'react';

import BasicPopup from '@/components/Common/BasicPopup';
import SortingButton from '@/components/Common/SortingButton';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

interface LicenseResponseDataProps {
  selectedFilterValue: SelectedLicenseFilterValue;
  data: LicenseInquiryResponse | null;
  currentPage: number;
  getLicenseData: () => void;
  ascending: boolean;
  updateAscending: () => void;
}

interface DataType {
  certificationImage?: string;
  fee?: number;
  id: number;
  key: number;
  index: number;
  insuranceRate?: string;
  licenseImage?: string;
  licenseSalesType?: string;
  licenseType?: string;
  locate?: string;
  price?: number;
  status?: string;
  tons?: string | number;
  useClassification?: string;
  year?: number | string;
  comment?: string;
  license?: LicenseData;
  isCompleted?: boolean;
}

interface LicenseInquiryModifyRequest {
  id: number;
  isCompleted: boolean;
}

const LicenseInquirySearchResult = ({
  data,
  currentPage,
  getLicenseData,
  ascending,
  updateAscending,
}: LicenseResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '요청자',
      dataIndex: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '연식',
      dataIndex: 'year',
    },
    {
      title: '톤수',
      dataIndex: 'tons',
    },
    {
      title: '번호판 종류',
      dataIndex: 'licenseType',
    },
    {
      title: '거래방식',
      dataIndex: 'licenseSalesType',
    },
    {
      title: '번호판 용도',
      dataIndex: 'useClassification',
    },
    {
      title: '지역',
      dataIndex: 'locate',
    },
    {
      title: '문의 완료 여부',
      dataIndex: 'isCompleted',
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (text, record) => (
        <Select
          value={record.isCompleted}
          options={[
            { value: true, label: '완료' },
            { value: false, label: '미완료' },
          ]}
          style={{ width: 90 }}
          onChange={(value) => handleStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: <SortingButton title="요청일자" isAscending={ascending} onclick={updateAscending} />,
      dataIndex: 'createdDate',
    },
    {
      title: '메모',
      dataIndex: 'memo',
      render: (text, record) => (
        <>
          {record.comment ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-1"
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                }}
              >
                {record.comment}
              </p>

              <Button onClick={() => handleMemoButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleMemoButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
    {
      title: '판매자정보',
      dataIndex: 'license',
      render: (text, record) => (
        <>
          {record.license && (
            <>
              {record.license?.seller ? (
                <>
                  판매자: {record?.license?.seller?.name} <br></br>
                  전화번호: {record?.license?.seller?.phoneNumber} <br></br>
                  판매금액: {record?.license?.price}만원
                </>
              ) : (
                <>
                  영업담당자: {record?.license?.salesPeople?.name} <br></br>
                  전화번호: {record?.license?.salesPeople?.phoneNumber} <br></br>
                  판매금액: {record?.license?.price}만원
                </>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
  const [memoContent, setMemoContent] = useState('');
  const [selectedMemoId, setSelectedMemoId] = useState(0);

  const [licenseInquiryModifyRequest, setLicenseInquiryModifyRequest] = useState<LicenseInquiryModifyRequest>({
    id: 0,
    isCompleted: false,
  });

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.member?.name || '-',
      phoneNumber: item.member?.phoneNumber || '-',
      tons: item.tons || '-',
      year: item.year || '-',
      licenseType: item.licenseType?.desc || '-',
      licenseSalesType: item.licenseSalesType?.desc || '-',
      useClassification: item.useClassification?.desc || '-',
      locate: item.locate?.desc || '-',
      status: item.license?.status?.desc || '-',
      isCompleted: item.isCompleted,
      comment: item.comment || '',
      createdDate: item.createdDate || '-',
      license: item.license || null,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleStatusChange = (value: boolean, record: DataType) => {
    setLicenseInquiryModifyRequest({
      id: record.id,
      isCompleted: value,
    });
    setIsOpenPopup(true);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { mutate } = useMutation(deleteProduct, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getLicenseData();
    },
    onError: (error: AxiosError) => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const hasSelected = selectedRowKeys.length > 0;

  const handleMemoButtonClick = (licenseInfo: any) => {
    setIsMemoModalVisible(true);
    setSelectedMemoId(licenseInfo.id);
    setIsDisabledEditBtn(true);
    setMemoContent(licenseInfo.comment);
  };

  const handleMemoModalClose = () => {
    setIsMemoModalVisible(false);
    setMemoContent('');
  };

  const handleMemoSave = () => {
    handleMemoModalClose();
    const request = {
      comment: memoContent,
    };
    patchLicenseCommentMutation.mutate(request);
  };

  const patchLicenseCommentMutation = useMutation(
    (requestData: { comment: string }) => patchLicenseInquiryComment(String(selectedMemoId), requestData),
    {
      onSuccess: () => {
        message.success('상세 정보가 수정되었습니다.', 2);
        setIsDisabledEditBtn(true);
        getLicenseData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const patchLicenseInquiryMutation = useMutation(
    (request: LicenseInquiryModifyRequest) => patchLicenseInquiry(request.id, { isCompleted: request.isCompleted }),
    {
      onSuccess: () => {
        message.success('문의 완료 여부가 변경되었습니다.', 2);
        setIsDisabledEditBtn(true);
        getLicenseData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const onChangeMemo = (memo: string) => {
    setMemoContent(memo);
    setIsDisabledEditBtn(false);
  };

  const onClickChangeIsCompleted = () => {
    patchLicenseInquiryMutation.mutate(licenseInquiryModifyRequest);
    setIsOpenPopup(false);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 번호판</span> {data?.totalElements}개
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="문의 완료 여부를 변경하시겠습니까?"
        content=""
        handleOk={() => onClickChangeIsCompleted()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
      <Modal
        title="메모 보기"
        open={isMemoModalVisible}
        onCancel={handleMemoModalClose}
        footer={[
          <Button key="close" onClick={handleMemoModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleMemoSave}
            disabled={isDisabledEditBtn}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={memoContent}
          onChange={(e) => onChangeMemo(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default LicenseInquirySearchResult;
