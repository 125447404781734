import { getPrices } from '@/api/public';
import ExcelUploader from '@/components/Common/ExcelUploader';
import PricesFilterSelector from '@/components/Prices/PricesFilterSelector';
import PricesSearchResult from '@/components/Prices/PricesSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const PricesList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<PricesFilterValue>({});

  const [searchResultData, setSearchResultData] = useState<PricesResponse | null>(null);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPricesData = async (page?: number) => {
    const { manufacturerCategories, model, tons, year, loaded, loadedInnerLength, axis } = selectedFilterValue;
    const queryParams = new URLSearchParams({
      tons: tons ? String(tons) : '',
      year: year ? String(year) : '',
      manufacturerCategoryId: manufacturerCategories || '',
      modelId: model || '',
      loaded: loaded || '',
      loadedInnerLength: loadedInnerLength ? String(loadedInnerLength) : '',
      axis: axis || '',
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getPrices(queryParams);
    setSearchResultData(response.data);
  };
  useEffect(() => {}, []);

  useEffect(() => {
    getPricesData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  return (
    <>
      <h2 className="hidden">시세관리 페이지 - 시세관리</h2>
      <h3 className="text-gray-8">시세관리</h3>
      <PricesFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getPricesData={() => getPricesData(currentPage)}
      ></PricesFilterSelector>
      <ExcelUploader path="/admin/v1/prices/excel"></ExcelUploader>
      <PricesSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getPricesData={() => getPricesData(currentPage)}
      ></PricesSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default PricesList;
