import { deleteNotice } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Table, TableColumnsType, message } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BasicPopup from '../Common/BasicPopup';

interface NoticeResponseDataProps {
  selectedFilterValue: SelectedNoticeFilterValue;
  data: PaginatedResponse<Notice> | null;
  currentPage: number;
  getNoticeData: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  title: string;
  contents: string;
  createdDate?: string;
  modifiedDate?: string;
  isDelete: boolean;
}

const NoticeSearchResult = ({ selectedFilterValue, data, currentPage, getNoticeData }: NoticeResponseDataProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isOpenPoppup, setIsOpenPopup] = useState(false);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const rowProps = (record: DataType) => ({
    onClick: () => {
      navigate(`/notice/${record.id}`, {
        state: { noticeData: data?.data?.filter((item, index) => item.id === record.id)[0] },
      });
    },
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '제목',
      dataIndex: 'title',
    },
    {
      title: '내용',
      dataIndex: 'contents',
      render: (text, record) => (
        <>
          <p
            className="mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-2"
            style={{
              maxWidth: '500px',
            }}
          >
            {record.contents}
          </p>
        </>
      ),
    },
    {
      title: '등록일자',
      dataIndex: 'createdDate',
    },
    {
      title: '수정일자',
      dataIndex: 'modifiedDate',
    },
    {
      title: '삭제여부',
      dataIndex: 'isDelete',
      render: (text, record) => (record.isDelete ? '삭제' : '미삭제'),
    },
  ];
  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      title: item.title || '',
      contents: item.contents || '',
      isDelete: item.isDelete,
      createdDate: item.createdDate,
      modifiedDate: item.modifiedDate,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const navigate = useNavigate();

  const onMoveRegisterPage = () => {
    navigate('/notice/form');
  };

  const showConfirm = () => {
    setIsOpenPopup(true);
  };

  const deleteNoticeMutation = useMutation(deleteNotice, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getNoticeData();
      setSelectedRowKeys([]);
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      deleteNoticeMutation.mutate(String(firstSelectedId));
      getNoticeData();
    } else {
      message.error('공지사항이 선택되지 않았어요. 삭제할 공지사항을 선택해주세요.');
    }
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 공지사항</span> {data?.totalElements}개
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}개 선택됨` : ''}</span>
          </div>
          <div>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>

        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onRow={rowProps}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
    </>
  );
};

export default NoticeSearchResult;
