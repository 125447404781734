import {
  getBannerFilterInfoEnum,
  getCapitalCounselServicesFilterInfoEnum,
  getJobFilterInfoEnum,
  getLicenseFilterInfoEnum,
  getNotificationFilterInfoEnum,
  getOneStopServicesFilterInfoEnum,
  getProductInquiryFilterInfoEnum,
  getProductPurchaseManageFilterInfoEnum,
  getProductsFilterInfoEnum,
  getPurchaseAccompanyingServicesFilterInfoEnum,
  getTransferAgencyServicesFilterInfoEnum,
} from '@/api/public';
import {
  AlertOutlined,
  CarOutlined,
  ContainerOutlined,
  DashboardOutlined,
  FlagOutlined,
  LineChartOutlined,
  PictureOutlined,
  PieChartOutlined,
  ReconciliationOutlined,
  SolutionOutlined,
  StockOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import React, { useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import useFetchUserInfo from '@/hooks/useFetchUserInfo';
import { bannerEnumAtom } from '@/store/banner';
import { capitalCounselServicesEnumAtom } from '@/store/capitalCounselServices';
import { jobEnumAtom } from '@/store/job';
import { licenseEnumAtom } from '@/store/license';
import { notificationEnumAtom } from '@/store/notification';
import { oneStopServicesEnumAtom } from '@/store/oneStopServices';
import { productInquiryEnumAtom } from '@/store/productInquiry';
import { productPurchaseManageEnumAtom } from '@/store/productPurchaseManage';
import { productsEnumAtom } from '@/store/products';
import { purchaseAccompanyingServicesEnumAtom } from '@/store/purchaseAccompanyingServices';
import { transferAgencyServicesEnumAtom } from '@/store/transferAgencyServices';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';
import { SvgLogo } from '../Icon';
import HeaderComponent from './HeaderComponent';

const { Content, Footer, Sider } = Layout;

const items = [
  { label: '대시보드', key: '1', icon: <PieChartOutlined />, link: '/' },
  { label: '회원관리', key: '2', icon: <SolutionOutlined />, link: '/members' },
  {
    label: '차량관리',
    key: 'sub1',
    icon: <CarOutlined />,
    link: '/vehicles/all',
    children: [
      { label: '전체 차량', key: '3', link: '/vehicles/all' },
      { label: '직트럭 상품용', key: '4', link: '/vehicles/assurance' },
      { label: '판매대행 차량', key: '5', link: '/vehicles/consignment' },
      { label: '매입견적 차량', key: '6', link: '/vehicles/speed' },
      { label: '더운반 차량', key: '7', link: '/vehicles/theunban' },
    ],
  },
  {
    label: '번호판 관리',
    key: 'sub2',
    icon: <DashboardOutlined />,
    link: '/license-plates',
    children: [
      { label: '번호판 조회', key: '8', link: '/license-plates' },
      { label: '번호판 구매문의', key: '9', link: '/license-plates/inquiry' },
    ],
  },
  {
    label: '차량 구매문의 관리',
    key: '10',
    icon: <ReconciliationOutlined />,
    link: '/product-inquiry',
  },
  {
    label: '부가 서비스 관리',
    key: 'sub3',
    icon: <ContainerOutlined />,
    link: '/transfer-agency-services',
    children: [
      {
        label: '판매대행 서비스 관리',
        key: '11',
        link: '/one-stop-services',
      },
      {
        label: '구매동행 서비스 관리',
        key: '12',
        link: '/purchase-accompanying-services',
      },
      { label: '이전대행 서비스 관리', key: '13', link: '/transfer-agency-services' },
      {
        label: '대출상담 서비스 관리',
        key: '14',
        link: '/capital-counsel-services',
      },
    ],
  },
  { label: '시세관리', key: '15', icon: <LineChartOutlined />, link: '/price-management' },
  { label: '배너관리', key: '16', icon: <PictureOutlined />, link: '/banner' },
  { label: '일자리 관리', key: '17', icon: <WalletOutlined />, link: '/job' },
  { label: '공지사항 관리', key: '18', icon: <FlagOutlined />, link: '/notice' },
  { label: '알림 관리', key: '19', icon: <AlertOutlined />, link: '/notification' },
  { label: '관리자 계정 관리', key: '20', icon: <TeamOutlined />, link: '/admin-accounts' },
  { label: '앱 다운로드 수 관리', key: '21', icon: <StockOutlined />, link: '/app-download-count' },
  { label: '내부 데이터 관리', key: '22', icon: <LineChartOutlined />, link: '/develop-data-management' },
];

const MainLayout = () => {
  const setMemberData = useFetchUserInfo();

  const navigate = useNavigate();
  const [productFilterInfo, setProductFilterInfo] = useAtom(productsEnumAtom);
  const [licenseFilterInfo, setLicenseFilterInfo] = useAtom(licenseEnumAtom);
  const [transferAgencyServicesFilterInfo, setTransferAgencyServicesFilterInfo] =
    useAtom(transferAgencyServicesEnumAtom);
  const [oneStopServicesFilterInfo, setOneStopServicesFilterInfo] = useAtom(oneStopServicesEnumAtom);
  const [productInquiryFilterInfo, setProductInquiryFilterInfo] = useAtom(productInquiryEnumAtom);
  const [productPurchaseManageFilterInfo, setproductPurchaseManageFilterInfo] = useAtom(productPurchaseManageEnumAtom);
  const [jobFilterInfo, setJobFilterInfo] = useAtom(jobEnumAtom);
  const [bannerFilterInfo, setBannerFilterInfo] = useAtom(bannerEnumAtom);
  const [notificationFilterInfo, setNotificationFilterInfo] = useAtom(notificationEnumAtom);
  const [purchaseAccompanyingServicesFilterInfo, setPurchaseAccompanyingServicesFilterInfo] = useAtom(
    purchaseAccompanyingServicesEnumAtom,
  );
  const [capitalCounselServiceFilterInfo, setCapitalCounselServiceFilterInfo] = useAtom(capitalCounselServicesEnumAtom);

  const getProductsFilterEnum = async () => {
    try {
      const response = await getProductsFilterInfoEnum();
      const res = response.data;
      setProductFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-products-filter-info'], getProductsFilterEnum, {
    staleTime: 600000,
  });

  const getTransferAgencyServicesFilterEnum = async () => {
    try {
      const response = await getTransferAgencyServicesFilterInfoEnum();
      const res = response.data;
      setTransferAgencyServicesFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-transfer-agency-services-filter-info'], getTransferAgencyServicesFilterEnum, {
    staleTime: 600000,
  });

  const getPurchaseAccompanyingServicesFilterEnum = async () => {
    try {
      const response = await getPurchaseAccompanyingServicesFilterInfoEnum();
      const res = response.data;
      setPurchaseAccompanyingServicesFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-purchase-accompanying-services-filter-info'], getPurchaseAccompanyingServicesFilterEnum, {
    staleTime: 600000,
  });

  const getLicenseFilterEnum = async () => {
    try {
      const response = await getLicenseFilterInfoEnum();
      const res = response.data;
      setLicenseFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-licenese-filter-info'], getLicenseFilterEnum, {
    staleTime: 600000,
  });

  const getOneStopServicesFilterEnum = async () => {
    try {
      const response = await getOneStopServicesFilterInfoEnum();
      const res = response.data;
      setOneStopServicesFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-one-stop-srevices-filter-info'], getOneStopServicesFilterEnum, {
    staleTime: 600000,
  });

  const getProductInquiryFilterEnum = async () => {
    try {
      const response = await getProductInquiryFilterInfoEnum();
      const res = response.data;
      setProductInquiryFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-product-inquiry-filter-info'], getProductInquiryFilterEnum, {
    staleTime: 600000,
  });

  const getProductPurchaseManageFilterEnum = async () => {
    try {
      const response = await getProductPurchaseManageFilterInfoEnum();
      const res = response.data;
      setproductPurchaseManageFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-product-purchase-manage-filter-info'], getProductPurchaseManageFilterEnum, {
    staleTime: 600000,
  });

  const getJobFilterEnum = async () => {
    try {
      const response = await getJobFilterInfoEnum();
      const res = response.data;
      setJobFilterInfo(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-job-filter-info'], getJobFilterEnum, {
    staleTime: 600000,
  });

  const getBannerFilterEnum = async () => {
    try {
      const response = await getBannerFilterInfoEnum();
      const res = response.data;
      setBannerFilterInfo(res);

      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const getNotificationFilterEnum = async () => {
    try {
      const response = await getNotificationFilterInfoEnum();
      const res = response.data;
      setNotificationFilterInfo(res);

      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const getCapitalCounselServicesFilterEnum = async () => {
    try {
      const response = await getCapitalCounselServicesFilterInfoEnum();
      const res = response.data;
      setCapitalCounselServiceFilterInfo(res);

      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  useQuery(['get-banner-filter-info'], getBannerFilterEnum, {
    staleTime: 600000,
  });

  useQuery(['get-notification-filter-info'], getNotificationFilterEnum, {
    staleTime: 600000,
  });

  useQuery(['get-capital-counsel-services-filter-info'], getCapitalCounselServicesFilterEnum, {
    staleTime: 600000,
  });

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleMenuClick = ({ key, keyPath }: { key: string; keyPath: string[] }) => {
    const parentKey = keyPath[keyPath.length - 1];
    const selectedItem = items.find((item) => item.key === key || (item.children && item.key === parentKey));

    if (selectedItem) {
      if (selectedItem.children) {
        const selectedSubItem = selectedItem.children.find((item) => item.key === key);
        if (selectedSubItem) {
          navigate(selectedSubItem.link);
        }
      } else if (selectedItem.link) {
        navigate(selectedItem.link);
      }
    }
  };

  useEffect(() => {
    getMemberData();
  }, []);

  const getMemberData = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      setMemberData(token);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', minWidth: '756px' }}>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          background: '#ffffff',
        }}
      >
        <Link to="/">
          <div className="demo-logo-vertical flex justify-center items-center h-[60px] cursor-pointer">
            <SvgLogo color={'#1E42A6'}></SvgLogo>
            <span className="font-bold ml-3 text-lg">관리자센터</span>
          </div>
        </Link>
        <Menu defaultSelectedKeys={['1']} mode="inline" items={items} onClick={handleMenuClick}></Menu>
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <HeaderComponent></HeaderComponent>
        <Content
          style={{
            margin: '16px',
            padding: 24,
            minHeight: 360,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflow: 'initial',
          }}
        >
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}직트럭</Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
