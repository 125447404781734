import { APPLICATION_JSON } from '@/const/headers';
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const apiManager: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 500000,
  headers: {
    'Content-Type': APPLICATION_JSON,
    'X-Requested-With': 'XMLHttpRequest',
  },
});

apiManager.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config.url) {
      if (!config.url.includes('login')) {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
      } else {
        if (config.headers['Authorization']) {
          delete config.headers['Authorization'];
        }
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiManager.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error.response);
    }
  },
);

export default apiManager;
