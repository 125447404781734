import JobForm from '@/components/Job/JobForm';
import { CONSIGNMENT } from '@/const/vehicles';
import React from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';

const JobRegisterUpdate = () => {
  const { id } = useParams();
  let tabs: Tab[] = [
    {
      key: 'info',
      label: '일자리 정보',
      children: <JobForm />,
    },
  ];
  return (
    <>
      <h3 className="text-gray-8">
        일자리 관리 /<span className="font-semibold"> 일자리 {id ? '수정' : '등록'}</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default JobRegisterUpdate;
