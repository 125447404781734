import React, { useEffect, useState } from 'react';

import { getProducts } from '@/api/public';
import VehicleFilterSelector from '@/components/Vehicles/Common/FilterSelector';
import ConsignmentSearchResult from '@/components/Vehicles/Consignment/ConsignmentSearchResult';
import { CONSIGNMENT } from '@/const/vehicles';
import { Pagination } from 'antd';

const ConsignmentList = () => {
  const restoredParam = new URLSearchParams(sessionStorage.getItem('consignmentListParam') || '');
  const productNumberString = restoredParam.get('productNumber');
  const productNumber = productNumberString !== null ? parseInt(productNumberString, 10) : null;
  const tonsNumberString = restoredParam.get('tons');
  const tons = tonsNumberString !== null ? parseInt(tonsNumberString, 10) : null;
  const loadedInnerLengthNumberString = restoredParam.get('loadedInnerLength');
  const loadedInnerLength = loadedInnerLengthNumberString !== null ? parseInt(loadedInnerLengthNumberString, 10) : null;
  const [ascending, setAscending] = useState(false);

  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedFilterValue>({
    productNumber: productNumber,
    truckName: restoredParam.get('truckName') || null,
    truckNumber: restoredParam.get('truckNumber') || null,
    status: restoredParam.get('productsStatus') || null,
    productsType: restoredParam.get('type') || null,
    salesType: restoredParam.get('salesType') || null,
    manufacturerCategories: restoredParam.get('manufacturerId') || null,
    model: restoredParam.get('modelId') || null,
    tons: tons,
    loaded: restoredParam.get('loaded') || null,
    loadedInnerLength: loadedInnerLength,
    axis: restoredParam.get('axis') || null,
    transmission: restoredParam.get('transmission') || null,
    startDate: restoredParam.get('startDate') || null,
    endDate: restoredParam.get('endDate') || null,
    isDelete: restoredParam.get('isDelete') || null,
    containTheUnban: restoredParam.get('containTheUnban') || null,
  });

  const [searchResultData, setSearchResultData] = useState<ProductsResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const getTruckData = async (page?: number) => {
    const {
      productNumber,
      truckName,
      truckNumber,
      status,
      productsType,
      manufacturerCategories,
      model,
      tons,
      loaded,
      loadedInnerLength,
      axis,
      transmission,
      startDate,
      endDate,
      isDelete,
      containTheUnban,
    } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      productNumber: productNumber ? String(productNumber) : '',
      truckName: truckName ? truckName : restoredParam?.get('truckName') || '',
      truckNumber: truckNumber ? truckNumber : restoredParam?.get('truckNumber') || '',
      productsStatus: status || '',
      type: productsType || '',
      manufacturerId: manufacturerCategories || '',
      modelId: model || '',
      tons: tons ? String(tons) : '',
      loaded: loaded || '',
      loadedInnerLength: loadedInnerLength ? String(loadedInnerLength) : '',
      axis: axis || '',
      transmission: transmission || '',
      startDate: startDate || '',
      endDate: endDate || '',
      salesType: CONSIGNMENT,
      ascending: String(ascending),
      isDelete: isDelete ? String(isDelete) : '',
      containTheUnban: containTheUnban ? String(containTheUnban) : 'false',
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getProducts(queryParams);

    sessionStorage.setItem('consignmentListParam', queryParams.toString());

    setSearchResultData(response.data);
  };

  useEffect(() => {
    getTruckData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  useEffect(() => {
    getTruckData();
  }, [ascending]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 판매대행 차량</h2>
      <h3 className="text-gray-8">
        차량관리 / <span className="font-semibold">판매대행 차량</span>
      </h3>
      <VehicleFilterSelector
        salesType={CONSIGNMENT}
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getTruckData={() => getTruckData(currentPage)}
      ></VehicleFilterSelector>
      <ConsignmentSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getTruckData={() => getTruckData(currentPage)}
        ascending={ascending}
        setAscending={setAscending}
      ></ConsignmentSearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default ConsignmentList;
