import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Tooltip, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';

import apiManager from '@/api/ApiManager';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import type { UploadFile } from 'antd';

interface FileUploaderProps {
  isShowImage: boolean;
  truckNumber?: string;
  fileData: ProductImage;
  setFileData: React.Dispatch<React.SetStateAction<ProductImage>>;
  fileNameMaxLength?: number;
  imageType: string;
  setIsDisabledEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
  type: string;
}

interface UploadItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  fileNameMaxLength?: number;
  fileData: ProductImage;
  isDisabled?: boolean;
}

const FileItem = ({ originNode, fileNameMaxLength, fileData, isDisabled }: UploadItemProps) => {
  const openFileDialog = () => {
    const inputElement = document.getElementById(`upload-button-${fileData.id}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  return (
    <div className="ant-upload-list-item-info" style={{ display: 'flex', overflow: 'hidden' }}>
      <div
        style={{
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: `${fileNameMaxLength}px`,
          width: 'auto',
        }}
      >
        {originNode}
      </div>
      <Button icon={<UploadOutlined />} style={{ margin: '0 8px' }} onClick={openFileDialog} disabled={isDisabled} />
    </div>
  );
};

const FileUploader = ({
  isShowImage,
  truckNumber,
  imageType,
  fileData,
  setFileData,
  fileNameMaxLength,
  setIsDisabledEditBtn,
  isDisabled,
  type,
}: FileUploaderProps) => {
  const [src, setSrc] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '1',
      name: fileData?.value,
      url: fileData?.value,
    },
  ]);

  useEffect(() => {
    setFileList([
      {
        uid: '1',
        name: fileData?.value,
        url: fileData?.value,
      },
    ]);
  }, [fileData]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;

    switch (type) {
      case 'license':
        const file = e.target.files[0];
        uploadLicenseImage(file);
        break;
      case 'productImage':
        const productImageFile = e.target.files[0];
        uploadImage(productImageFile);
        break;
      case 'banner':
        const bannerFile = e.target.files[0];
        uploadBannerImage(bannerFile);
        break;
    }
  };

  const uploadImage = async (file: File) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }

    if (!truckNumber) {
      message.error('차량번호를 입력해주세요.', 1);
      return;
    }

    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('truckNumber', truckNumber);
    formData.append('imageType', imageType);

    reader.onload = () => {
      setSrc(reader.result as string);
      setFileName(file.name);
    };

    reader.readAsDataURL(file);

    try {
      const response = await apiManager.post('/admin/v1/products-images', formData, {
        headers: {
          'Content-Type': MULTIPART_FORM_DATA,
        },
      });
      if (response.data) {
        if (setIsDisabledEditBtn) {
          setIsDisabledEditBtn(false);
        }
        setFileList([{ uid: file.name, name: file.name, status: 'done', url: response.data.url.toString() }]);

        setFileData((prevData) => ({
          ...prevData,
          value: response.data?.url.toString(),
        }));
      }
    } catch (error) {
      throw new Error('Error');
    }
  };

  const uploadLicenseImage = async (file: File) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('licenseImageType', imageType);

    reader.onload = () => {
      setSrc(reader.result as string);
      setFileName(file.name);
    };

    reader.readAsDataURL(file);
    const response = await apiManager.post('/admin/v1/license/upload', formData, {
      headers: {
        'Content-Type': MULTIPART_FORM_DATA,
      },
    });
    if (response.data) {
      if (setIsDisabledEditBtn) {
        setIsDisabledEditBtn(false);
      }
      setFileList([{ uid: file.name, name: file.name, status: 'done', url: response.data.url.toString() }]);

      setFileData((prevData) => ({
        ...prevData,
        value: response.data?.url.toString(),
      }));
    }
  };

  const uploadBannerImage = async (file: File) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('uploadFile', file);
    if (truckNumber) {
      formData.append('truckNumber', truckNumber);
    }
    formData.append('imageType', imageType);

    reader.onload = () => {
      setSrc(reader.result as string);
      setFileName(file.name);
    };

    reader.readAsDataURL(file);
    const response = await apiManager.post('/admin/v1/banners/upload', formData, {
      headers: {
        'Content-Type': MULTIPART_FORM_DATA,
      },
    });
    if (response.data) {
      if (setIsDisabledEditBtn) {
        setIsDisabledEditBtn(false);
      }
      setFileList([{ uid: file.name, name: file.name, status: 'done', url: response.data.url.toString() }]);

      setFileData((prevData) => ({
        ...prevData,
        value: response.data?.url.toString(),
      }));
    }
  };

  const customRemoveIcon = (file: UploadFile) => {
    return (
      <Tooltip title="파일 삭제">
        <DeleteOutlined />
      </Tooltip>
    );
  };

  const handleRemove = (file: UploadFile) => {
    if (isDisabled) return;
    const updatedFileList = fileList.filter((f) => f.uid !== file.uid);
    setFileList(updatedFileList);
    setFileData((prevData) => ({
      ...prevData,
      value: '',
    }));
    if (setIsDisabledEditBtn) {
      setIsDisabledEditBtn(false);
    }
  };

  return (
    <>
      {isShowImage && (
        <div className="w-[198px] h-[132px] m-2">
          {fileList[0] && fileList[0].url !== '' ? (
            <img src={fileList[0].url} alt="truck_img" className="w-full h-full object-cover" />
          ) : (
            <div className="w-full h-full bg-[#DCDCDC]" />
          )}
        </div>
      )}

      <Upload
        style={{ display: 'flex' }}
        multiple={false}
        fileList={fileList}
        showUploadList={{ showDownloadIcon: false, showRemoveIcon: !isDisabled, removeIcon: customRemoveIcon }}
        itemRender={(originNode, file) => (
          <FileItem
            originNode={originNode}
            fileNameMaxLength={fileNameMaxLength}
            fileData={fileData}
            isDisabled={isDisabled}
          />
        )}
        onRemove={handleRemove}
      ></Upload>

      <input
        id={`upload-button-${fileData?.id}`}
        type="file"
        onChange={(e) => handleChange(e)}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default FileUploader;
