import React, { useEffect, useState } from 'react';

import { getBannerList } from '@/api/public';
import FilterSelector from '@/components/Banner/FilterSelector';
import SearchResult from '@/components/Banner/SearchResult';
import { Pagination } from 'antd';

const BannerList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterValue, setSelectedFilterValue] = useState<BannerSelectedFilterValue>({
    bannerType: null,
    bannerLocation: null,
    display: 'true',
  });
  const [searchResultData, setSearchResultData] = useState<BannerResponse | null>(null);

  const getBannerData = async (page?: number) => {
    const { bannerType, bannerLocation, display } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      bannerType: bannerType || '',
      bannerLocation: bannerLocation || '',
      display: display || '',
      page: String(page ? page : 1),
    });

    const response = await getBannerList(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getBannerData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h3 className="text-gray-8">배너 관리</h3>
      <FilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getBannerData={() => getBannerData(currentPage)}
      ></FilterSelector>
      <SearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getBannerData={() => getBannerData(currentPage)}
      ></SearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default BannerList;
