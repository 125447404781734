import { SearchOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React from 'react';

interface SearchProps {
  selectedFilterValue: DelayProductsSelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<DelayProductsSelectedFilterValue>>;
  getDelayProductData: () => void;
}
const DelayProductsFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getDelayProductData,
}: SearchProps) => {
  const convertDelayProductsFilterInfoToSelectOptions = () => {
    return {
      salesType: [
        { value: 'NORMAL,CONSIGNMENT,THIRD_PARTY_DEALER', label: '직거래 차량' },
        { value: 'ASSURANCE', label: '직매입 차량' },
      ],
    };
  };

  const selectOptions = convertDelayProductsFilterInfoToSelectOptions();

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      salesType: '',
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="체결여부"
          optionFilterProp="children"
          options={selectOptions.salesType}
          value={selectedFilterValue?.salesType || null}
          onChange={(value) => onChangeSelect('salesType', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getDelayProductData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};
export default DelayProductsFilterSelector;
