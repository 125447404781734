import { getContractsList } from '@/api/public';
import ContractsFilterSelector from '@/components/Contracts/ContractsFilterSelector';
import ContractsSearchResult from '@/components/Contracts/ContractsSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ContractsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterValue, setSelectedFilterValue] = useState<ContractSelectedFilterValue>({
    isCompleted: '',
  });

  const location = useLocation();
  const completedContractCount = location.state?.completedContractCount ? location.state.completedContractCount : 0;
  const notCompletedContractCount = location.state?.notCompletedContractCount
    ? location.state.notCompletedContractCount
    : 0;

  const [searchResultData, setSearchResultData] = useState<ContractPagingResponse | null>(null);

  const getContractsData = async (page?: number) => {
    const { isCompleted } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      isCompleted: isCompleted,
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getContractsList(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getContractsData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h3 className="text-gray-8">전자계약 리스트</h3>

      <ContractsFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getContractsData={() => getContractsData(currentPage)}
      ></ContractsFilterSelector>
      <ContractsSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getContractsData={() => getContractsData(currentPage)}
        completedContractCount={completedContractCount}
        notCompletedCount={notCompletedContractCount}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default ContractsList;
