import { Button, Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface NotificationResponseDataProps {
  selectedFilterValue: SelectedNotificationFilterValue;
  data: NotificationResponse | null;
  currentPage: number;
  getNotificationData: () => void;
  ascending: boolean;
  updateAscending: () => void;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  notificationType: EnumPresenter;
  title: string;
  content: string;
  reservationDate?: string;
  isSend: boolean;
  sendCount: number;
  sendSuccessCount: number;
  createdDate: string;
  createdBy: string;
}

const NotificationSearchResult = ({
  selectedFilterValue,
  data,
  currentPage,
  getNotificationData,
  ascending,
  updateAscending,
}: NotificationResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },

    {
      title: '타입',
      dataIndex: 'notificationType',
      render: (text, record) => record.notificationType?.desc || '-',
    },
    {
      title: '제목',
      dataIndex: 'title',
    },
    {
      title: '알림내용',
      dataIndex: 'content',
    },
    {
      title: '예약시간',
      dataIndex: 'reservationDate',
    },
    {
      title: '전송여부',
      dataIndex: 'isSend',
      render: (text, record) => (record.isSend ? '전송' : '미전송'),
    },
    {
      title: '발송/성공 카운트',
      dataIndex: 'count',
      render: (text, record) => record.sendCount + '/' + record.sendSuccessCount,
    },

    {
      title: '등록일자',
      dataIndex: 'createdDate',
    },
    {
      title: '등록자',
      dataIndex: 'createdBy',
    },
  ];

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      notificationType: item.notificationType || '',
      title: item.title || '',
      content: item.contents || '',
      reservationDate: item.reservationDate || '',
      isSend: item.isSend,
      sendCount: item.sendCount,
      sendSuccessCount: item.sendSuccessCount,
      createdDate: item.createdDate || '-',
      createdBy: item.createdBy || '-',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const navigate = useNavigate();
  const onMoveRegisterPage = () => {
    navigate('/notification/reservation/form');
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 요청건수</span> {data?.totalElements}개
          </div>
          <div>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
          </div>
        </div>

        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </>
  );
};

export default NotificationSearchResult;
