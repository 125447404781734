import { transferAgencyServicesEnumAtom } from '@/store/transferAgencyServices';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import { useAtom } from 'jotai';
import React from 'react';

const { RangePicker } = DatePicker;

interface SearchProps {
  selectedFilterValue: SelectedTransferAgencyServicesFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedTransferAgencyServicesFilterValue>>;
  getTransferAgencyServicesData: () => void;
}

const TransferAgencyServicesFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getTransferAgencyServicesData,
}: SearchProps) => {
  const [transferAgencyServicesFilterInfo, setTransferAgencyServicesFilterInfo] =
    useAtom(transferAgencyServicesEnumAtom);
  const convertTransferAgencyServicesFilterInfoSelectOptions = (
    transferAgencyServicesFilterInfo: TransferAgencyServicesFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(transferAgencyServicesFilterInfo.status),
    };
  };

  const selectOptions = convertTransferAgencyServicesFilterInfoSelectOptions(transferAgencyServicesFilterInfo);

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };
  const clear = () => {
    setSelectedFilterValue({
      status: null,
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="상태"
          optionFilterProp="children"
          options={selectOptions.status}
          value={selectedFilterValue.status}
          onChange={(value) => onChangeSelect('status', value)}
        />
      </div>

      <div className="w-full flex mb-2">
        <div className="w-full"></div>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          className="bg-[#1890ff] mx-2"
          onClick={getTransferAgencyServicesData}
        >
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default TransferAgencyServicesFilterSelector;
