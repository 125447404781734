import { Skeleton, Tabs } from 'antd';
import { useParams } from 'react-router-dom';

import { getLicensesDetail } from '@/api/public';
import LicenseInfo from '@/components/License/List/LicenseInfo';
import LicenseInquiryHistory from '@/components/License/List/LicenseInquiryHistory';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

const LicenseDetail = () => {
  const { id } = useParams();
  const licenseId = id;

  const getLicenseDetail = async (licenseId?: string) => {
    if (!licenseId) return;
    try {
      const response = await getLicensesDetail(licenseId);
      const res = response.data;
      setLicenseDetail(res);
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data, isError, error, isLoading, isFetching } = useQuery(
    ['get-license-detail', licenseId],
    () => getLicenseDetail(licenseId),
    {
      refetchOnWindowFocus: false,
    },
  );

  const [licenseDetail, setLicenseDetail] = useState<LicenseDetailResponse>(data);

  const updateInquiryIsCompleted = (id: number, isCompleted: boolean) => {
    setLicenseDetail((prev) => ({
      ...prev,
      licenseInquiries: prev.licenseInquiries.map((inquiry) => {
        if (id === inquiry.id) {
          return { ...inquiry, isCompleted: isCompleted };
        } else {
          return inquiry;
        }
      }),
    }));
  };

  let tabs: Tab[] = [
    {
      key: 'info',
      label: '번호판 정보',
      children: (
        <>
          <Skeleton active loading={isFetching}>
            {<LicenseInfo licenseId={licenseId} data={data}></LicenseInfo>}
          </Skeleton>
          {licenseId && (
            <LicenseInquiryHistory
              data={licenseDetail}
              updateInquiryCompleted={updateInquiryIsCompleted}
            ></LicenseInquiryHistory>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <h2 className="hidden">번호판 관리 페이지 - 번호판 조회 - 번호판 {licenseId ? '상세' : '등록'}</h2>
      <h3 className="text-gray-8">
        번호판 관리 / 번호판 조회 / <span className="font-semibold">번호판 {licenseId ? '상세' : '등록'}</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default LicenseDetail;
