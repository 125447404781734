import { patchCapitalCounselServicesCounselStatusChange } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { capitalCounselServicesEnumAtom } from '@/store/capitalCounselServices';
import { convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, message, Modal, Select, Table, TableColumnsType } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BasicPopup from '../Common/BasicPopup';

interface CapitalCounselServicesResponseDataProps {
  selectedFilterValue: SelectedCapitalCounselServicesFilterValue;
  data: CapitalCounselServicesResponse | null;
  currentPage: number;
  getCapitalCounselServicesData: () => void;
}

interface CounselStatusChangeRequest {
  id: string;
  counselStatus: string;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  name: string;
  phoneNumber: string;
  counselStatus: EnumPresenter;
  productId?: number;
  productNumber?: number;
  price?: number;
  createdDate?: string;
  memo?: string;
}

const CapitalCounselServicesSearchResult = ({
  selectedFilterValue,
  data,
  currentPage,
  getCapitalCounselServicesData,
}: CapitalCounselServicesResponseDataProps) => {
  const navigate = useNavigate();
  const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
  const [memoContent, setMemoContent] = useState('');
  const [id, setId] = useState<string>('');
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [counselStatusRequest, setCounselStatusRequest] = useState<CounselStatusChangeRequest>({
    id: '',
    counselStatus: '',
  });
  const [capitalCounselServicesFilterInfo, setCapitalCounselServicesFilterInfo] =
    useAtom(capitalCounselServicesEnumAtom);

  const convertCapitalCounselServicesFilterInfoSelectOptions = (
    capitalCounselServicesFilterInfo: CapitalCounselServicesFilterInfoType,
  ) => {
    return {
      counselStatus: convertToSelectOptions(capitalCounselServicesFilterInfo.counselStatus),
    };
  };

  const selectOptions = convertCapitalCounselServicesFilterInfoSelectOptions(capitalCounselServicesFilterInfo);

  const handleCounselStatusChange = (value: string, record: DataType) => {
    setIsOpenPopup(true);
    const request = {
      id: String(record.id),
      counselStatus: value,
    };
    setCounselStatusRequest(request);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '신청자',
      dataIndex: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '상태',
      dataIndex: 'counselStatus',
      render: (text, record) => (
        <Select
          value={record.counselStatus?.desc}
          options={selectOptions.counselStatus}
          style={{ width: 90 }}
          onChange={(value) => handleCounselStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '거래금액',
      dataIndex: 'price',
      render: (text, record) => <>{record.price}만원</>,
    },
    {
      title: '요청일자',
      dataIndex: 'createdDate',
    },
    {
      title: '메모',
      dataIndex: 'memo',
      render: (text, record) => (
        <>
          {record.memo ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-1"
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                }}
              >
                {record.memo}
              </p>

              <Button onClick={() => handleMemoButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleMemoButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const handleMemoButtonClick = (capitalCounselServices: any) => {
    setIsMemoModalVisible(true);
    setId(capitalCounselServices.id);
    setMemoContent(capitalCounselServices.memo);
  };

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.name,
      phoneNumber: item.phoneNumber,
      counselStatus: item.counselStatus || null,
      productId: item.productId,
      productNumber: item.productNumber,
      price: item.price,
      createdDate: item.createdDate || '-',
      memo: item.memo || '',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);
  const onClickCounselStatusChangeButton = () => {
    patchCapitalCounselServicesCounselStatusModifyMutation.mutate(counselStatusRequest);
  };
  const onClickCancelButton = () => {
    setIsOpenPopup(false);
    setCounselStatusRequest({ id: '', counselStatus: '' });
  };
  const patchCapitalCounselServicesCounselStatusModifyMutation = useMutation(
    (requestData: { id: string; memo?: string; counselStatus?: string }) =>
      patchCapitalCounselServicesCounselStatusChange(requestData),
    {
      onSuccess: () => {
        message.success('상태가 변경되었습니다.', 2);

        getCapitalCounselServicesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
        setCounselStatusRequest({ id: '', counselStatus: '' });
      },
    },
  );

  const onChangeMemo = (memo: string) => {
    setMemoContent(memo);
  };

  const handleMemoModalClose = () => {
    setIsMemoModalVisible(false);
    setMemoContent('');
  };

  const handleMemoSave = () => {
    handleMemoModalClose();
    const request = {
      id: id,
      memo: memoContent,
    };
    patchCapitalCounselServicesCounselStatusModifyMutation.mutate(request);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 신청건수</span> {data?.totalElements}개
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="상태를 변경하겠습니까?"
        content=""
        handleOk={() => onClickCounselStatusChangeButton()}
        handleCancel={() => onClickCancelButton()}
      ></BasicPopup>

      <Modal
        title="메모 보기"
        open={isMemoModalVisible}
        onCancel={handleMemoModalClose}
        footer={[
          <Button key="close" onClick={handleMemoModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleMemoSave}
            disabled={!!!memoContent}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={memoContent}
          onChange={(e) => onChangeMemo(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default CapitalCounselServicesSearchResult;
