// SalesType
export const ALL = 'ALL';
export const NORMAL = 'NORMAL';
export const ASSURANCE = 'ASSURANCE';
export const CONSIGNMENT = 'CONSIGNMENT';

// Theunban 차량 구분 목적
export const THEUNBAN = 'THEUNBAN';

// ProductsType
export const SPEED = 'SPEED';
export const DIRECT = 'DIRECT';
