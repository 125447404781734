import { getNotification } from '@/api/public';
import NotificationFilterSelector from '@/components/Notification/NotificationFilterSelector';
import NotificationSearchResult from '@/components/Notification/NotificationSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const NotificationList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedNotificationFilterValue>({
    notificationType: null,
    isSend: null,
    reservationStartDate: null,
    reservationEndDate: null,
    ascending: false,
  });
  const [searchResultData, setSearchResultData] = useState<NotificationResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getNotificationData = async (page?: number) => {
    const { notificationType, isSend, reservationStartDate, reservationEndDate, ascending } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      notificationType: notificationType || '',
      isSend: isSend || '',
      reservationStartDate: reservationStartDate || '',
      reservationEndDate: reservationEndDate || '',
      ascending: String(ascending),
      page: String(page ? page : 1),
      size: '10',
    });
    const response = await getNotification(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getNotificationData(currentPage);
  }, [currentPage, selectedFilterValue.ascending]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  return (
    <>
      <h2 className="hidden">알림관리 - 알림 내역</h2>
      <h3 className="text-gray-8">
        알림 관리 / <span className="font-semibold">알림내역</span>
      </h3>
      <NotificationFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getNotificationData={() => getNotificationData(currentPage)}
      ></NotificationFilterSelector>

      <NotificationSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getNotificationData={() => getNotificationData(currentPage)}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      />

      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default NotificationList;
