import { getDashboard, getFunctionActiveReport, getSalesReport } from '@/api/public';
import DashboardSearchResult from '@/components/Dashboard/DashboardSearchResult';
import FunctionActiveReportSearchResult from '@/components/Dashboard/FunctionActiveReportSearchResult';
import SalesReportSearchResult from '@/components/Dashboard/SalesReportSearchResult';
import StatisticsReportSearchResult from '@/components/Dashboard/StatisticsReportSearchResult';
import React, { useEffect, useState } from 'react';

const Dashboard = () => {
  const [searchSalesReportResultData, setSearchSalesReportResultData] = useState<SalesReportResponse | null>(null);
  const [searchFunctionActiveReportSearchResultData, setSearchFunctionActiveReportSearchResult] =
    useState<FunctionActiveReportResponse | null>(null);

  const [searchDashboardResultData, setSearchDashboardResultData] = useState<DashboardReportResponse | null>(null);

  const getFunctionActiveReportSearchResultData = async () => {
    const response = await getFunctionActiveReport();
    setSearchFunctionActiveReportSearchResult(response.data);
  };
  const getSalesReportData = async () => {
    const response = await getSalesReport();
    setSearchSalesReportResultData(response.data);
  };
  const getDashboardData = async () => {
    const response = await getDashboard();
    setSearchDashboardResultData(response.data);
  };

  useEffect(() => {
    getSalesReportData();
    getFunctionActiveReportSearchResultData();
    getDashboardData();
  }, []);

  return (
    <>
      <DashboardSearchResult data={searchDashboardResultData} getDashboardData={getDashboardData} />
      <StatisticsReportSearchResult />
      <SalesReportSearchResult data={searchSalesReportResultData} />
      <FunctionActiveReportSearchResult data={searchFunctionActiveReportSearchResultData} />
    </>
  );
};

export default Dashboard;
