import apiManager from '@/api/ApiManager';
import { fetchMemberData } from '@/api/public';
import jwtDecode from 'jwt-decode';
import { memberAtom } from '@/store/member';
import { useEffect } from 'react';
import { useSetAtom } from 'jotai';

const useFetchUserInfo = () => {
  const setMemberDataAtom = useSetAtom(memberAtom);

  const fetchUserInfo = async (token: string) => {
    if (token) {
      localStorage.setItem('token', token);
      try {
        const decodedToken: Token = jwtDecode(token);
        const parse = JSON.parse(decodedToken.details.toString());

        if (parse) {
          const response = await fetchMemberData(parse['memberId']);
          setMemberDataAtom(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return fetchUserInfo;
};

export default useFetchUserInfo;
