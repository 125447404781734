import { atom } from 'jotai';

export const jobEnumAtom = atom({
  status: [],
  salaryType: [],
  workingArea: [],
  workingDays: [],
  workingHours: [],
  period: [],
});
