import { Button, Modal } from 'antd';
import React, { ReactNode } from 'react';

interface PopupProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  content?: string;
  handleOk: () => void;
  handleCancel: () => void;
  icon: ReactNode;
}

const BasicPopup = ({ isOpen, setIsOpen, title, content, handleOk, handleCancel, icon }: PopupProps) => {
  return (
    <Modal
      open={isOpen}
      title={
        <span>
          {icon}
          {title}
        </span>
      }
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          취소
        </Button>,
        <Button key="submit" type="primary" className="bg-[#1890ff]" onClick={handleOk}>
          확인
        </Button>,
      ]}
    ></Modal>
  );
};

export default BasicPopup;
