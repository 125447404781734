import { Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';

interface DataType {
  id: number;
  productNumber: number;
  truckName: string;
  truckNumber: string;
  status: EnumPresenter;
  salesType: EnumPresenter;
  manufacturerCategoryName: string;
  modelName: string;
  firstRegDate: string;
  loadedInnerLength: number;
  transmission: EnumPresenter;
  distance: number;
  price: number;
  salesDate: string;
  delayedDay: number;
}

const DelayProductsSearchResult = ({
  selectedFilterValue,
  data,
  getDelayProductData,
  assuranceCount,
  notAssuranceCount,
}: DelayProductsResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '차량번호',
      dataIndex: 'truckNumber',
    },

    {
      title: '매물 유형',
      dataIndex: 'salesType',
      render: (text, record) => record.salesType.desc,
    },
    {
      title: '제조사',
      dataIndex: 'manufacturerCategoryName',
    },
    {
      title: '모델',
      dataIndex: 'modelName',
    },
    {
      title: '연식',
      dataIndex: 'firstRegDate',
    },
    {
      title: '적재함 길이',
      dataIndex: 'loadedInnerLength',
    },

    {
      title: '변속기',
      dataIndex: 'transmission',
      render: (text, record) => record.transmission.desc,
    },
    {
      title: '주행거리',
      dataIndex: 'distance',
    },
    {
      title: '판매가격',
      dataIndex: 'price',
    },
    {
      title: '등록일자',
      dataIndex: 'salesDate',
    },
    {
      title: '경과일',
      dataIndex: 'delayedDay',
    },
  ];

  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      productNumber: item.productNumber,
      truckName: item.truckName,
      truckNumber: item.truckNumber,
      status: item.status,
      salesType: item.salesType,
      manufacturerCategoryName: item.manufacturerCategoryName,
      modelName: item.modelName,
      firstRegDate: item.firstRegDate,
      loadedInnerLength: item.loadedInnerLength,
      transmission: item.transmission,
      distance: item.distance,
      price: item.price,
      salesDate: item.salesDate,
      delayedDay: item.delayedDay,
    })) || [];
  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">직거래 차량</span> {notAssuranceCount}대
            <span className="font-bold ml-2">직매입 차량</span> {assuranceCount}대
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </>
  );
};

export default DelayProductsSearchResult;
