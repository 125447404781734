import { postNotification } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE, NO_REQUIRED_VALUE_ERROR_MESSAGE } from '@/const/errorMessage';
import { notificationEnumAtom } from '@/store/notification';
import { convertToSelectOptions } from '@/utils/common';
import { Button, Col, DatePicker, DatePickerProps, Divider, Input, Row, Select, message } from 'antd';
import ko from 'antd/es/date-picker/locale/ko_KR';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const NotificationReservationForm = () => {
  const navigate = useNavigate();

  const postNotificationReservationMutation = useMutation(
    (requestData: SelectedNotificationValue) => postNotification(requestData),
    {
      onSuccess: () => {
        message.success('예약알림이 등록되었습니다.', 2);
        navigate('/notification');
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const onClickRegister = () => {
    const { notificationType, reservationDate, title, contents, redirectUrl } = selectedValue;

    if (notificationType === '' || reservationDate === '' || title === '' || contents === '') {
      message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
    } else {
      const request = {
        notificationType,
        reservationDate: reservationDate ? reservationDate.concat(':00') : '',
        title,
        contents,
        redirectUrl,
      };

      postNotificationReservationMutation.mutate(request);
    }
  };
  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [notificationFilterInfo, setNotificationFilterInfo] = useAtom(notificationEnumAtom);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);

  const convertNotificationFilterInfoToSelectOptions = (notificationFilterInfo: NotificationFilterInfoType) => {
    return {
      notificationType: convertToSelectOptions(notificationFilterInfo.notificationType),
    };
  };

  const [selectedValue, setSelectedValue] = useState<SelectedNotificationValue>({
    notificationType: '',
    reservationDate: '',
    title: '',
    contents: '',
    redirectUrl: '',
  });

  const onChange: DatePickerProps['onChange'] = (_, dateStr) => {
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      reservationDate: dateStr,
    }));
  };

  const onChangeInput = (name: string, value: string | boolean) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const selectOptions = convertNotificationFilterInfoToSelectOptions(notificationFilterInfo);

  const onChangeSelect = (name: string, value: string | boolean) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            알림 타입
            <span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style}>
            <Select
              className="mr-4 my-2"
              style={{ width: 200 }}
              placeholder="알림 타입 선택"
              optionFilterProp="children"
              options={selectOptions.notificationType}
              value={selectedValue?.notificationType}
              onChange={(value) => onChangeSelect('notificationType', value)}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            예약 일시<span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style}>
            <DatePicker
              className="mr-4 my-2"
              style={{ width: 200 }}
              showTime={{ format: 'HH:mm', minuteStep: 30 }}
              locale={ko}
              onChange={onChange}
              format="YYYY-MM-DD HH:mm"
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            제목<span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={20}>
          <div style={style}>
            <TextArea
              value={selectedValue.title || ''}
              onChange={(e) => onChangeInput('title', e.target.value)}
              placeholder="알림 제목 입력"
              autoSize={{ minRows: 8, maxRows: 8 }}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>
            내용<span className="font-normal text-red-500 ml-1">(필수)</span>
          </div>
        </Col>
        <Col className="gutter-row" span={20}>
          <div style={style}>
            <TextArea
              value={selectedValue.contents || ''}
              onChange={(e) => onChangeInput('contents', e.target.value)}
              placeholder="알림 내용 입력"
              autoSize={{ minRows: 8, maxRows: 8 }}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div style={labelStyle}>리다이렉트 url</div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div style={style}>
            <Input
              style={{ width: '100%' }}
              value={selectedValue.redirectUrl || ''}
              onChange={(e) => onChangeInput('redirectUrl', e.target.value)}
              placeholder="리다이렉트 주소"
            />
          </div>
        </Col>
      </Row>

      <Divider orientation="left"></Divider>

      <div className="flex justify-end">
        <Button type="primary" className="mx-2 w-20" onClick={onClickRegister} disabled={isDisabledEditBtn}>
          등록하기
        </Button>
      </div>
    </>
  );
};

export default NotificationReservationForm;
