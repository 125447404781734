import { patchLicenseInquiry } from '@/api/public';
import BasicPopup from '@/components/Common/BasicPopup';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Empty, Select, Table, TableProps, Tabs, message } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

interface LicenseInquriesProps {
  data: LicenseDetailResponse | null;
  updateInquiryCompleted: (id: number, isCompleted: boolean) => void;
}

interface DataType {
  key: number;
  isCompleted: boolean;
  id: number;
  name: string;
  phoneNumber: string;
}

const LienseInquiryHistory = ({ data, updateInquiryCompleted }: LicenseInquriesProps) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [inquiryKey, setInquiryKey] = useState('');
  const [licenseInquiryModifyRequest, setLicenseInquiryModifyRequest] = useState<LicenseInquiryModifyRequest>({
    id: 0,
    isCompleted: false,
  });

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '요청자 이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '요청자 전화번호',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: '문의 완료 여부',
      dataIndex: 'isCompleted',
      onCell: (record) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
          },
        };
      },
      render: (text, record) => (
        <Select
          value={record.isCompleted}
          options={[
            { value: true, label: '완료' },
            { value: false, label: '미완료' },
          ]}
          style={{ width: 90 }}
          onChange={(value) => handleStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '',
      dataIndex: 'isCompleted',
      key: 'isCompleted',
      render: (isCompleted, data: DataType) => (
        <>{!isCompleted && <Button onClick={() => handleInquiryCompletedButton(data.id)}>완료처리</Button>}</>
      ),
    },
  ];

  const onClickChangeCompleted = () => {
    setIsOpenPopup(false);
    patchLicenseInquiryMutation.mutate(licenseInquiryModifyRequest);
    setInquiryKey('');
  };

  const onClickChangeCancel = () => {
    setInquiryKey('');
    setIsOpenPopup(false);
  };

  const handleInquiryCompletedButton = (id: number) => {
    setInquiryKey(String(id));
    setIsOpenPopup(true);
  };

  const patchLicenseInquiryMutation = useMutation(
    (request: LicenseInquiryModifyRequest) => patchLicenseInquiry(request.id, { isCompleted: request.isCompleted }),
    {
      onSuccess: (response) => {
        message.success('문의 완료 여부가 변경되었습니다.', 2);
        updateInquiryCompleted(response.data.id, response.data.isCompleted);
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const updatedTableData =
    data?.licenseInquiries?.map((item) => ({
      id: item.id,
      key: item.id,
      isCompleted: item.isCompleted,
      name: item.member.name,
      phoneNumber: item.member.phoneNumber,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  let tabs: Tab[] = [
    {
      key: 'inquiryHistory',
      label: '번호판 문의 이력',
      children: (
        <>
          {tableData.length > 0 ? (
            <Table columns={columns} dataSource={tableData} />
          ) : (
            <Empty description="번호판 문의 이력이 없습니다" />
          )}
        </>
      ),
    },
  ];

  const handleStatusChange = (value: boolean, record: DataType) => {
    setLicenseInquiryModifyRequest({
      id: record.id,
      isCompleted: value,
    });
    setIsOpenPopup(true);
  };

  return (
    <>
      <Tabs defaultActiveKey="inquiryHistory" items={tabs}></Tabs>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="문의 완료 여부를 변경하시겠습니까?"
        content=""
        handleOk={() => onClickChangeCompleted()}
        handleCancel={onClickChangeCancel}
      ></BasicPopup>
    </>
  );
};

export default LienseInquiryHistory;
