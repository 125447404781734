import { Button, Col, Divider, Input, Radio, Row, Select, message } from 'antd';
import React, { useState } from 'react';

import { patchProductsDetail } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { ALL } from '@/const/vehicles';
import { productsEnumAtom } from '@/store/products';
import { convertToSelectOptions } from '@/utils/common';
import type { RadioChangeEvent } from 'antd';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import CheckBoxComponent from '../../Common/CheckBoxComponent';

interface ProductImage {
  id: string;
  name: string;
  value: string;
}

const { TextArea } = Input;

const VehicleDetailContent = ({ salesType, data, updateData }: ProductDetailResponseDataProps) => {
  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };

  const [messageApi, contextHolder] = message.useMessage();

  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'This is an error message',
    });
  };

  const [productFilterEnum, setProductFilterEnum] = useAtom(productsEnumAtom);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);

  const [selectedMaintenanceList, setSelectedMaintenanceList] = useState(
    data?.maintenance?.maintenanceData.map((item: any) => item.code),
  );
  const [selectedNormalOptionList, setSelectedNormalOptionList] = useState(
    data?.carOption?.normalOption?.option?.map((item: any) => item.code),
  );
  const [selectedAdditionalOptionList, setSelectedAdditionalOptionList] = useState(
    data?.carOption?.additionalOption?.option?.map((item: any) => item.code),
  );
  const [selectedBreakOptionList, setSelectedBreakOptionList] = useState(
    data?.carOption?.breakOption?.option?.map((item: any) => item.code),
  );

  const keyValueList: KeyValueListType = {
    certificateImageUrl: '차량등록증',
    frontImageUrl: '앞면',
    frontSideImageUrl: '앞측면',
    backImageUrl: '후면',
    backSideImageUrl: '후측면',
    tireImageUrl: '타이어',
    engineImageUrl: '엔진',
    insideImageUrl: '실내',
    dashboardImageUrl: '계기판',
    sheetImageUrl: '시트',
  };

  const productImageData = data?.productsImage
    ? Object.entries(data?.productsImage)
        .filter(([key, value]) => key !== 'id' && key !== 'optionImageUrl' && key !== 'engineVideoUrl')
        ?.map(([key, value]) => ({ id: key, name: keyValueList[key], value: value }))
    : [];

  const [productImagesList, setProductImagesList] = useState<ProductImage[]>(productImageData);

  const convertManufacturerCategoriesToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
  ): { value: string; label: string }[] => {
    return manufacturerCategoriesWithModels.map((item) => ({
      value: item.manufacturerCategories.id.toString(),
      label: item.manufacturerCategories.name,
    }));
  };

  const convertModelsToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
    selectedManufacturerCategoryId: string,
  ): { value: string; label: string }[] => {
    const selectedManufacturerCategory = manufacturerCategoriesWithModels.find(
      (item) => item.manufacturerCategories.id.toString() === selectedManufacturerCategoryId,
    );
    return selectedManufacturerCategory
      ? selectedManufacturerCategory.model.map((model) => ({
          value: model.id.toString(),
          label: model.name,
        }))
      : [];
  };

  const convertProductsFilterInfoToSelectOptions = (productFilterEnum: ProductsFilterInfoType) => {
    return {
      axis: convertToSelectOptions(productFilterEnum.axis),
      loaded: convertToSelectOptions(productFilterEnum.loaded),
      manufacturerCategories: convertManufacturerCategoriesToSelectOptions(
        productFilterEnum.manufacturerCategoriesWithModels,
      ),
      model: convertModelsToSelectOptions(
        productFilterEnum.manufacturerCategoriesWithModels,
        selectedManufacturerCategoryId,
      ),
      productsType: convertToSelectOptions(productFilterEnum.productsType),
      status: convertToSelectOptions(productFilterEnum.status),
      transmission: convertToSelectOptions(productFilterEnum.transmission),
      locate: convertToSelectOptions(productFilterEnum.locate),
      fuel: convertToSelectOptions(productFilterEnum.fuel),
      color: convertToSelectOptions(productFilterEnum.color),
    };
  };

  const [selectedManufacturerCategoryId, setSelectedManufacturerCategoryId] = useState<string>(
    String(data.manufacturerCategories.id),
  );

  const selectOptions = convertProductsFilterInfoToSelectOptions(productFilterEnum);

  const [selectedValue, setSelectedValue] = useState<SelectedValue>({
    axis: data?.axis?.code,
    distance: data?.distance,
    manufacturerCategories: String(data?.manufacturerCategories.id),
    model: String(data?.model.id),
    status: data?.status?.code,
    productsType: data?.type?.code,
    price: data?.price,
    fuel: data?.fuel?.code,
    transmission: data?.transmission?.code,
    tireStatus: data?.tireStatus?.code,
    garage: data?.garage?.code,
    power: data?.power,
    loaded: data?.loaded?.code,
    loadedInnerLength: data?.loadedInnerLength,
    loadedInnerArea: data?.loadedInnerArea,
    loadedInnerHeight: data?.loadedInnerHeight,
    color: data?.color?.code,
    isAccident: data?.accidentsHistory.accident,
    accidentContents: data?.accidentsHistory.accidentContents,
    transportStartLocate: data?.transportStartLocate?.code,
    transportEndLocate: data?.transportEndLocate?.code,
    transportGoods: data?.transportGoods,
    salesPeople: data?.salesPeople?.id ? data?.salesPeople?.id : null,
    maintenanceEtc: data?.maintenance?.etc,
    normalOptionEtc: data?.carOption?.normalOption?.etc,
    additionalOptionEtc: data?.carOption?.additionalOption?.etc,
    breakOptionEtc: data?.carOption?.breakOption?.etc,
    detailContent: data?.detailContent,
  });

  const onChangeInput = (name: string, value: string) => {
    setIsDisabledEditBtn(false);

    if (name === 'manufacturerCategories') {
      setSelectedManufacturerCategoryId(value);
      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
        model: null,
      }));
    } else {
      setSelectedValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
      }));
    }
  };

  const { mutate } = useMutation(
    (requestData: ProductUpdateRequest) => patchProductsDetail(String(data.id), requestData),
    {
      onSuccess: (response) => {
        message.success('상세 정보가 수정되었습니다.', 2);
        setIsDisabledEditBtn(true);
        if (updateData) {
          updateData(response.data);
        }
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const isValueChanged = (dataValue: any, selectedValue: any) => dataValue !== selectedValue;

  const onClickEditBtn = () => {
    if (!data?.id) {
      return;
    }

    const request: ProductUpdateRequest = {
      accident: isValueChanged(data?.accidentsHistory?.accident, selectedValue.isAccident)
        ? selectedValue.isAccident
        : undefined,
      accidentContents: isValueChanged(data?.accidentsHistory?.accidentContents, selectedValue.accidentContents)
        ? selectedValue.accidentContents
        : undefined,
      tireStatus: isValueChanged(data?.tireStatus?.code, selectedValue.tireStatus)
        ? selectedValue.tireStatus
        : undefined,
      transportGoods: isValueChanged(data?.transportGoods, selectedValue.transportGoods)
        ? selectedValue.transportGoods
        : undefined,
      transportStartLocate: isValueChanged(data?.transportStartLocate?.code, selectedValue.transportStartLocate)
        ? selectedValue.transportStartLocate
        : undefined,
      transportEndLocate: isValueChanged(data?.transportEndLocate?.code, selectedValue.transportEndLocate)
        ? selectedValue.transportEndLocate
        : undefined,
      detailContents: isValueChanged(data?.detailContent, selectedValue.detailContent)
        ? selectedValue.detailContent
        : undefined,
    };

    const isMaintenanceValueChanged =
      JSON.stringify(Object.values(data?.maintenance?.maintenanceData.map((item) => item.code))) !==
        JSON.stringify(selectedMaintenanceList) || data?.maintenance?.etc !== selectedValue.maintenanceEtc;

    const isCarOptionValueChanged =
      JSON.stringify(Object.values(data?.carOption?.normalOption?.option.map((item) => item.code))) !==
        JSON.stringify(selectedNormalOptionList) ||
      JSON.stringify(Object.values(data?.carOption?.additionalOption?.option.map((item) => item.code))) !==
        JSON.stringify(selectedAdditionalOptionList) ||
      JSON.stringify(Object.values(data?.carOption?.breakOption?.option.map((item) => item.code))) !==
        JSON.stringify(selectedBreakOptionList) ||
      data?.carOption?.normalOption?.etc !== selectedValue.normalOptionEtc ||
      data?.carOption?.additionalOption?.etc !== selectedValue.additionalOptionEtc ||
      data?.carOption?.breakOption?.etc !== selectedValue.breakOptionEtc;

    if (isMaintenanceValueChanged) {
      request.maintenanceData = {
        maintenanceCategories: selectedMaintenanceList,
        etc: selectedValue.maintenanceEtc || '',
      };
    }

    if (isCarOptionValueChanged) {
      request.carOption = {
        normalOption: {
          option: selectedNormalOptionList,
          etc: selectedValue.normalOptionEtc || '',
        },
        additionalOption: {
          option: selectedAdditionalOptionList,
          etc: selectedValue.additionalOptionEtc || '',
        },
        breakOption: {
          option: selectedBreakOptionList,
          etc: selectedValue.breakOptionEtc || '',
        },
      };
    }

    mutate(request);
  };

  const onChangeRadio = (key: string, e: RadioChangeEvent) => {
    setIsDisabledEditBtn(false);
    const val = e.target.value;

    if (key === 'tireStatus') {
      setSelectedValue({ ...selectedValue, tireStatus: val });
    } else if (key === 'isAccident') {
      setSelectedValue((prev) => {
        const updatedValue = { ...prev, isAccident: val };
        if (val === false) {
          updatedValue.accidentContents = null;
        }
        return updatedValue;
      });
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>사고 유무</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.accidentsHistory?.accident ? '사고' : '무사고'}</div>
          ) : (
            <div style={style}>
              <Radio.Group onChange={(e) => onChangeRadio('isAccident', e)} value={selectedValue?.isAccident}>
                <Radio value={true}>있음</Radio>
                <Radio value={false}>없음</Radio>
              </Radio.Group>
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>사고 상세 내용</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.accidentsHistory?.accidentContents || '-'}</div>
          ) : (
            <TextArea
              value={selectedValue.accidentContents || ''}
              onChange={(e) => onChangeInput('accidentContents', e.target.value)}
              placeholder="사고 상세 내용 입력"
              autoSize={{ minRows: 5, maxRows: 5 }}
              disabled={!selectedValue?.isAccident}
            />
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>타이어 상태</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.tireStatus?.desc || '-'}</div>
          ) : (
            <div style={style}>
              <Radio.Group onChange={(e) => onChangeRadio('tireStatus', e)} value={selectedValue?.tireStatus}>
                <Radio value="GOOD">상</Radio>
                <Radio value="FAIR">중</Radio>
                <Radio value="POOR">하</Radio>
              </Radio.Group>
            </div>
          )}
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>운송 물품</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>{data?.transportGoods || '-'}</div>
          ) : (
            <div style={style}>
              <Input
                className="mr-1"
                placeholder="운송 물품"
                style={{ width: '100%', minWidth: 86, maxWidth: 128 }}
                value={selectedValue?.transportGoods || ''}
                onChange={(e) => onChangeInput('transportGoods', e.target.value)}
              />
            </div>
          )}
        </Col>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>주요 운행 구간</div>
        </Col>
        <Col className="gutter-row" span={5}>
          {salesType === ALL ? (
            <div style={style}>
              {data?.transportStartLocate?.desc} ~{data?.transportEndLocate?.desc}
            </div>
          ) : (
            <div style={style}>
              <Select
                style={{ width: '100%', minWidth: 86, maxWidth: 108 }}
                placeholder="OO도"
                optionFilterProp="children"
                options={selectOptions.locate}
                value={selectedValue.transportStartLocate}
                onChange={(value) => onChangeInput('transportStartLocate', value)}
              />
              <span className="mx-2">~</span>
              <Select
                className="mr-4"
                style={{ width: '100%', minWidth: 86, maxWidth: 108 }}
                placeholder="OO도"
                optionFilterProp="children"
                options={selectOptions.locate}
                value={selectedValue.transportEndLocate}
                onChange={(value) => onChangeInput('transportEndLocate', value)}
              />
            </div>
          )}
        </Col>
      </Row>

      <Divider></Divider>

      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 정비 이력</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.maintenanceCategories}
            selectedList={selectedMaintenanceList}
            setSelectedList={setSelectedMaintenanceList}
            isDisabled={salesType === ALL ? true : false}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          {salesType === ALL ? (
            <div style={style}>직접입력: {data?.maintenance?.etc || '-'}</div>
          ) : (
            <div className="flex" style={style}>
              <div className="w-[60px]">직접입력 </div>
              <Input
                className="mr-1"
                placeholder="직접입력"
                style={{ width: '100%' }}
                value={selectedValue.maintenanceEtc || ''}
                onChange={(e) => onChangeInput('maintenanceEtc', e.target.value)}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 옵션</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.normalOption}
            selectedList={selectedNormalOptionList}
            setSelectedList={setSelectedNormalOptionList}
            isDisabled={salesType === ALL ? true : false}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          {salesType === ALL ? (
            <div style={style}>직접입력: {data?.carOption?.normalOption?.etc || '-'}</div>
          ) : (
            <div className="flex" style={style}>
              <div className="w-[60px]">직접입력 </div>
              <Input
                className="mr-1"
                placeholder="직접입력"
                style={{ width: '100%' }}
                value={selectedValue.normalOptionEtc || ''}
                onChange={(e) => onChangeInput('normalOptionEtc', e.target.value)}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>추가 옵션</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.additionalOption}
            selectedList={selectedAdditionalOptionList}
            setSelectedList={setSelectedAdditionalOptionList}
            isDisabled={salesType === ALL ? true : false}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          {salesType === ALL ? (
            <div style={style}>직접입력: {data?.carOption?.additionalOption?.etc || '-'}</div>
          ) : (
            <div className="flex" style={style}>
              <div className="w-[60px]">직접입력 </div>
              <Input
                className="mr-1"
                placeholder="직접입력"
                style={{ width: '100%' }}
                value={selectedValue.additionalOptionEtc || ''}
                onChange={(e) => onChangeInput('additionalOptionEtc', e.target.value)}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>브레이크 옵션</div>
        </Col>
        <Col className="gutter-row mb-8" span={21}>
          <CheckBoxComponent
            optionList={productFilterEnum.breakOption}
            selectedList={selectedBreakOptionList}
            setSelectedList={setSelectedBreakOptionList}
            isDisabled={salesType === ALL ? true : false}
            setIsDisabledEditBtn={setIsDisabledEditBtn}
          ></CheckBoxComponent>
          {salesType === ALL ? (
            <div style={style}>직접입력: {data?.carOption?.breakOption?.etc || '-'}</div>
          ) : (
            <div className="flex" style={style}>
              <div className="w-[60px]">직접입력 </div>
              <Input
                className="mr-1"
                placeholder="직접입력"
                style={{ width: '100%' }}
                value={selectedValue.breakOptionEtc || ''}
                onChange={(e) => onChangeInput('breakOptionEtc', e.target.value)}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="gutter-row" span={3}>
          <div style={labelStyle}>차량 상세 설명</div>
        </Col>
        <Col className="gutter-row" span={21}>
          {salesType === ALL ? (
            <div style={style}>{data?.detailContent || '-'}</div>
          ) : (
            <div style={style}>
              <TextArea
                className="mr-1"
                placeholder="차량 상세 설명"
                style={{ width: '100%' }}
                value={selectedValue?.detailContent || ''}
                onChange={(e) => onChangeInput('detailContent', e.target.value)}
                autoSize={{ minRows: 5 }}
              />
            </div>
          )}
        </Col>
      </Row>
      <Divider orientation="left"></Divider>

      {salesType !== ALL && (
        <>
          <div className="flex justify-end">
            <Button
              type="primary"
              className="bg-[#1890ff] mx-2 w-20"
              disabled={isDisabledEditBtn}
              onClick={onClickEditBtn}
            >
              수정하기
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default VehicleDetailContent;
