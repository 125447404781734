import { patchPurchaseAccompanyingServices } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { purchaseAccompanyingServicesEnumAtom } from '@/store/purchaseAccompanyingServices';
import { convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import BasicPopup from '../Common/BasicPopup';

interface PurchaseAccompanyingServicesResponseDataProps {
  data: PurchaseAccompanyingServicesResponse | null;
  currentPage: number;
  getPurchaseAccompanyingServicesData: () => void;
}

interface ModifyRequest {
  status?: string;
  comment?: string;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  name: string;
  phoneNumber: string;
  status: EnumPresenter;
  productId: number;
  productNumber?: number;
  truckName: string;
  loaded?: EnumPresenter;
  loadedInnerLength: number;
  loadedInnerArea?: number;
  loadedInnerHeight?: number;
  transmission?: EnumPresenter;
  year: string;
  price?: number;
  actualSalePrice?: number;
  createdDate?: string;
  comment?: string;
}

const PurchaseAccompanyingServicesSearchResult = ({
  data,
  currentPage,
  getPurchaseAccompanyingServicesData,
}: PurchaseAccompanyingServicesResponseDataProps) => {
  const [id, setId] = useState<number>(0);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [statusRequest, setStatusRequest] = useState<ModifyRequest>({ status: '', comment: '' });
  const [purchaseAccompanyingServicesFilterInfo, setPurchaseAccompanyingServicesFilterInfo] = useAtom(
    purchaseAccompanyingServicesEnumAtom,
  );
  const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
  const [memoContent, setMemoContent] = useState('');

  const handleStatusChange = (value: string, record: DataType) => {
    setIsOpenPopup(true);
    const request = {
      status: value,
    };
    setId(record.id);
    setStatusRequest(request);
  };

  const handleMemoButtonClick = (purchaseAccompanyingService: any) => {
    setIsMemoModalVisible(true);
    setId(purchaseAccompanyingService.id);
    setMemoContent(purchaseAccompanyingService.comment);
  };

  const convertPurchaseAccompanyingServicesFilterInfoSelectOptions = (
    purchaseAccompanyingServicesFilterInfo: PurchaseAccompanyingServicesFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(purchaseAccompanyingServicesFilterInfo.status),
    };
  };

  const selectOptions = convertPurchaseAccompanyingServicesFilterInfoSelectOptions(
    purchaseAccompanyingServicesFilterInfo,
  );

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '신청자',
      dataIndex: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
      render: (text, record) => (
        <Select
          value={record.status?.desc}
          options={selectOptions.status}
          style={{ width: 90 }}
          onChange={(value) => handleStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '특장길이(길이/넓이/높이)',
      dataIndex: 'loadedLength',
      render: (text, record) => (
        <>
          {record.loadedInnerLength}
          {!!record.loadedInnerArea ? ' / ' + record.loadedInnerArea : ''}
          {!!record.loadedInnerHeight ? ' / ' + record.loadedInnerHeight : ''}
        </>
      ),
    },
    {
      title: '변속기',
      dataIndex: 'transmission',
      render: (text, record) => <>{record.transmission ? record.transmission.desc : ''}</>,
    },
    {
      title: '년식',
      dataIndex: 'year',
    },
    {
      title: '거래금액',
      dataIndex: 'price',
      render: (text, record) => (
        <>
          {record.actualSalePrice
            ? !!record.actualSalePrice
              ? record.actualSalePrice + '만원'
              : ''
            : !!record.price
            ? record.price + '만원'
            : ''}
        </>
      ),
    },
    {
      title: '요청일자',
      dataIndex: 'createdDate',
    },
    {
      title: '메모',
      dataIndex: 'memo',
      render: (text, record) => (
        <>
          {record.comment ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-1"
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                }}
              >
                {record.comment}
              </p>

              <Button onClick={() => handleMemoButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleMemoButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.name,
      phoneNumber: item.phoneNumber,
      status: item.status || null,
      productId: item.product?.id,
      productNumber: item.product?.productsNumber,
      truckName: item.product?.truckName || '',
      loaded: item.product?.loaded,
      loadedInnerLength: item.product?.loadedInnerLength,
      loadedInnerArea: item.product?.loadedInnerArea,
      loadedInnerHeight: item.product?.loadedInnerHeight,
      transmission: item.product?.transmission,
      year: item.product?.year || '',
      price: item.product?.price,
      actualSalePrice: item.product?.actualSalePrice,
      createdDate: item.createdDate || '-',
      comment: item.comment || '',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const patchPurchaseAccompanyingServicesModifyMutation = useMutation(
    (requestData: { status?: string; comment?: string }) =>
      patchPurchaseAccompanyingServices(id.toString(), requestData),
    {
      onSuccess: () => {
        message.success('상태가 변경되었습니다.', 2);

        getPurchaseAccompanyingServicesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
        setStatusRequest({ status: '' });
      },
    },
  );

  const onClickCancelButton = () => {
    setIsOpenPopup(false);
    setStatusRequest({ status: '', comment: '' });
  };

  const onClickStatusChangeButton = () => {
    patchPurchaseAccompanyingServicesModifyMutation.mutate(statusRequest);
  };

  const handleMemoModalClose = () => {
    setIsMemoModalVisible(false);
    setMemoContent('');
  };

  const handleMemoSave = () => {
    handleMemoModalClose();
    const request = {
      comment: memoContent,
    };
    patchPurchaseAccompanyingServicesModifyMutation.mutate(request);
  };

  const onChangeMemo = (memo: string) => {
    setMemoContent(memo);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 신청건수</span> {data?.totalElements}개
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="상태를 변경하겠습니까?"
        content=""
        handleOk={() => onClickStatusChangeButton()}
        handleCancel={() => onClickCancelButton()}
      ></BasicPopup>

      <Modal
        title="메모 보기"
        open={isMemoModalVisible}
        onCancel={handleMemoModalClose}
        footer={[
          <Button key="close" onClick={handleMemoModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleMemoSave}
            disabled={!!!memoContent}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={memoContent}
          onChange={(e) => onChangeMemo(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
    </>
  );
};

export default PurchaseAccompanyingServicesSearchResult;
