import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import Search from 'antd/es/input/Search';
import dayjs from 'dayjs';
import React, { useState } from 'react';
const { RangePicker } = DatePicker;
interface SearchProps {
  selectedFilterValue: SelectedNoticeFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedNoticeFilterValue>>;
  getNotificationData: () => void;
}

const NoticeFilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getNotificationData }: SearchProps) => {
  const [noticeFilterInfo, setNoticeFilterInfo] = useState();

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      title: null,
      isDelete: null,
      startDate: null,
      endDate: null,
    });
  };

  const handleSearch = () => {
    getNotificationData();
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Search
          className="mr-4"
          placeholder="제목 입력"
          allowClear
          onSearch={handleSearch}
          style={{ width: 180 }}
          value={selectedFilterValue?.title || ''}
          onChange={(e) => handleInputStringChange('title', e.target.value)}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="삭제 여부"
          optionFilterProp="children"
          options={[
            { label: '삭제', value: 'true' },
            { label: '미삭제', value: 'false' },
          ]}
          value={selectedFilterValue.isDelete}
          onChange={(value) => onChangeSelect('isDelete', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <div className="w-full">
          <span className="mr-2">등록일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getNotificationData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default NoticeFilterSelector;
