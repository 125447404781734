import { patchTransferAgencyServicesStatusChange } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { transferAgencyServicesEnumAtom } from '@/store/transferAgencyServices';
import { convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Select, Table, TableColumnsType, message } from 'antd';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BasicPopup from '../Common/BasicPopup';

interface TransferAgencyServicesResponseDataProps {
  selectedFilterValue: SelectedTransferAgencyServicesFilterValue;
  data: TransferAgencyServicesResponse | null;
  currentPage: number;
  getTransferAgencyServicesData: () => void;
}

interface StatusChangeRequest {
  id: string;
  status: string;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  name: string;
  phoneNumber: string;
  status: EnumPresenter;
  productId?: number;
  productNumber?: number;
  truckName?: string;
  loaded?: EnumPresenter;
  loadedInnerLength?: number;
  loadedInnerArea?: number;
  loadedInnerHeight?: number;
  transmission?: EnumPresenter;
  year?: string;
  price?: number;
  actualSalePrice?: number;
  createdDate?: string;
}

const TransferAgencyServicesSearchResult = ({
  selectedFilterValue,
  data,
  currentPage,
  getTransferAgencyServicesData,
}: TransferAgencyServicesResponseDataProps) => {
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [statusRequest, setStatusRequest] = useState<StatusChangeRequest>({ id: '', status: '' });
  const [transferAgencyServicesFilterInfo, setTransferAgencyServicesFilterInfo] =
    useAtom(transferAgencyServicesEnumAtom);

  const handleStatusChange = (value: string, record: DataType) => {
    setIsOpenPopup(true);
    const request = {
      id: String(record.id),
      status: value,
    };
    setStatusRequest(request);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '신청자',
      dataIndex: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
      render: (text, record) => (
        <Select
          value={record.status?.desc}
          options={selectOptions.status}
          style={{ width: 90 }}
          onChange={(value) => handleStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '특장길이(길이/넓이/높이)',
      dataIndex: 'loadedLength',
      render: (text, record) => (
        <>
          {record.loadedInnerLength ? (
            <>
              {record.loadedInnerLength}
              <span> / </span> {record.loadedInnerArea ? record.loadedInnerArea : 0} <span> / </span>
              {record.loadedInnerHeight ? record.loadedInnerHeight : 0}
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: '변속기',
      dataIndex: 'transmission',
      render: (text, record) => <>{record.transmission ? record.transmission.desc : ''}</>,
    },
    {
      title: '년식',
      dataIndex: 'year',
    },
    {
      title: '거래금액',
      dataIndex: 'price',
      render: (text, record) => <>{record.actualSalePrice ? record.actualSalePrice : record.price}만원</>,
    },
    {
      title: '요청일자',
      dataIndex: 'createdDate',
    },
  ];

  const convertTransferAgencyServicesFilterInfoToSelectOptions = (
    transferAgencyServicesFilterInfo: TransferAgencyServicesFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(transferAgencyServicesFilterInfo.status),
    };
  };
  const selectOptions = convertTransferAgencyServicesFilterInfoToSelectOptions(transferAgencyServicesFilterInfo);

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.member.name,
      phoneNumber: item.member.phoneNumber,
      status: item.status || null,
      productId: item.product?.id,
      productNumber: item.product?.productsNumber,
      truckName: item.product?.truckName || '',
      loaded: item.product?.loaded,
      loadedInnerLength: item.product?.loadedInnerLength,
      loadedInnerArea: item.product?.loadedInnerArea,
      loadedInnerHeight: item.product?.loadedInnerHeight,
      transmission: item.product?.transmission,
      year: item.product?.year || '',
      price: item.product?.price,
      actualSalePrice: item.product?.actualSalePrice,
      createdDate: item.createdDate || '-',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const patchTransferAgencyServicesStatusModifyMutation = useMutation(
    (requestData: { id: string; status: string }) => patchTransferAgencyServicesStatusChange(requestData),
    {
      onSuccess: () => {
        message.success('상태가 변경되었습니다.', 2);

        getTransferAgencyServicesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
        setStatusRequest({ id: '', status: '' });
      },
    },
  );

  const onClickCancelButton = () => {
    setIsOpenPopup(false);
    setStatusRequest({ id: '', status: '' });
  };
  const onClickStatusChangeButton = () => {
    patchTransferAgencyServicesStatusModifyMutation.mutate(statusRequest);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 신청건수</span> {data?.totalElements}개
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="상태를 변경하겠습니까?"
        content=""
        handleOk={() => onClickStatusChangeButton()}
        handleCancel={() => onClickCancelButton()}
      ></BasicPopup>
    </>
  );
};

export default TransferAgencyServicesSearchResult;
