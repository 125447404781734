import { Button, Select } from 'antd';

import { bannerEnumAtom } from '@/store/banner';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import React from 'react';

interface SearchProps {
  selectedFilterValue: BannerSelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<BannerSelectedFilterValue>>;
  getBannerData: () => void;
}
const FilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getBannerData }: SearchProps) => {
  const [bannerFilterInfo] = useAtom(bannerEnumAtom);

  const convertBannerFilterInfoToSelectOptions = (bannerFilterInfo: BannerFilterInfoType) => {
    return {
      bannersType: convertToSelectOptions(bannerFilterInfo.bannersType),
      bannerLocation: convertToSelectOptions(bannerFilterInfo.bannerLocation),
      display: [
        { value: 'true', label: '노출' },
        { value: 'false', label: '미노출' },
      ],
    };
  };

  const selectOptions = convertBannerFilterInfoToSelectOptions(bannerFilterInfo);

  const clear = () => {
    setSelectedFilterValue({
      bannerType: null,
      bannerLocation: null,
      display: null,
    });
  };

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex justify-between">
        <div className="">
          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="배너 타입"
            optionFilterProp="children"
            options={selectOptions.bannersType}
            value={selectedFilterValue?.bannerType}
            onChange={(value) => onChangeSelect('bannerType', value)}
          />

          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="배너 노출 위치"
            optionFilterProp="children"
            options={selectOptions.bannerLocation}
            value={selectedFilterValue?.bannerLocation}
            onChange={(value) => onChangeSelect('bannerLocation', value)}
          />

          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="노출 여부"
            optionFilterProp="children"
            options={selectOptions.display}
            value={selectedFilterValue?.display}
            onChange={(value) => onChangeSelect('display', value)}
          />
        </div>
        <div>
          <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getBannerData}>
            검색
          </Button>
          <Button onClick={clear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

export default FilterSelector;
