import { Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';

interface DataType {
  id: number;
  key: React.Key;
  baseDate: string;
  downloadAppCount: number;
}
const AppDownloadCountSearchResult = ({ selectedFilterValue, data, getDashboardData }: DashboardResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: '날짜',
      dataIndex: 'baseDate',
    },
    {
      title: '앱 다운로드 수',
      dataIndex: 'downloadAppCount',
    },
  ];

  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      baseDate: item.baseDate,
      downloadAppCount: item.downloadAppCount,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}></div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </>
  );
};

export default AppDownloadCountSearchResult;
