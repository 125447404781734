import { Col, Divider, Row } from 'antd';

import React from 'react';

const VehicleHistoryContent = ({ data }: ProductDetailResponseDataProps) => {
  const style: React.CSSProperties = { padding: '8px 0' };

  return (
    <>
      <dl>
        <dt className="font-semibold mb-2">실소유자 정보 (현물출자)</dt>
        <dd className="my-1">{data?.lastOwnerInfo?.content || '-'}</dd>
      </dl>
      <Divider></Divider>
      <dl>
        <dt className="font-semibold mb-2">거래 이력</dt>
        {data?.tradingHistory?.map((item, index) => (
          <dd key={`${item.date}-${index}`} className="my-1">
            • {item.date} {item.content}
          </dd>
        ))}
      </dl>
      <Divider></Divider>
      <dl>
        <dt className="font-semibold mb-2">자동차 검사 이력</dt>
        {data?.inspectionHistory?.map((item, index) => (
          <dd key={`${item.date}-${index}`} className="my-1">
            • {item.date} {item.content}
          </dd>
        ))}
      </dl>
      <Divider></Divider>
      <dl>
        <dt className="font-semibold mb-2">변경 이력</dt>
        {data?.tuningHistory?.map((item, index) => (
          <dd key={`${item.date}-${index}`} className="my-1">
            • {item.date}
            <span className="ml-4 block"> • 구조 변경 전: {item.before}</span>
            <span className="ml-4 block"> • 구조 변경 후: {item.after}</span>
          </dd>
        ))}
      </dl>
    </>
  );
};

export default VehicleHistoryContent;
