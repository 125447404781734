import { Button, Upload, message } from 'antd';
import React from 'react';

import apiManager from '@/api/ApiManager';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import { UploadOutlined } from '@ant-design/icons';

interface ExcelUploaderProps {
  path: string;
  afterUploadRunningMethod?: () => void;
}

const ExcelUploader = ({ path, afterUploadRunningMethod }: ExcelUploaderProps) => {
  const handleFileUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await apiManager.post(path, formData, {
        headers: {
          'Content-Type': MULTIPART_FORM_DATA,
        },
      });

      message.success('업로드 완료되었습니다.');
    } catch (error) {
      message.error('업로드를 실패했습니다.');
    } finally {
      if (afterUploadRunningMethod) {
        afterUploadRunningMethod();
      }
    }
  };

  const beforeUpload = (file: File) => {
    handleFileUpload(file);
    return false;
  };

  return (
    <>
      <div>
        <Upload
          showUploadList={false}
          style={{ display: 'flex' }}
          multiple={false}
          accept=".xlsx, .xls"
          beforeUpload={beforeUpload}
        >
          <Button icon={<UploadOutlined />}>엑셀파일 업로드</Button>
        </Upload>

        <input type="file" style={{ display: 'none' }} />
      </div>
    </>
  );
};

export default ExcelUploader;
