import React, { useEffect, useState } from 'react';

import { getJobList } from '@/api/public';
import { Pagination } from 'antd';
import FilterSelector from '@/components/Job/FilterSelector';
import SearchResult from '@/components/Job/SearchResult';

const JobList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterValue, setSelectedFilterValue] = useState<JobSelectedFilterValue>({
    status: null,
    title: '',
    minTons: null,
    maxTons: null,
    salaryType: null,
    startDate: '',
    endDate: '',
  });
  const [searchResultData, setSearchResultData] = useState<JobResponse | null>(null);

  const getJobData = async (page?: number) => {
    const { status, title, minTons, maxTons, salaryType, startDate, endDate } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      status: status || '',
      title: title || '',
      minTons: minTons ? String(minTons) : '',
      maxTons: maxTons ? String(maxTons) : '',
      salaryType: salaryType || '',
      startDate: startDate || '',
      endDate: endDate || '',
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getJobList(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getJobData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h3 className="text-gray-8">
        일자리 관리 /<span className="font-semibold"> 일자리 목록</span>
      </h3>
      <FilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getJobData={() => getJobData(currentPage)}
      ></FilterSelector>
      <SearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getJobData={() => getJobData(currentPage)}
      ></SearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default JobList;
