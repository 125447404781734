import { capitalCounselServicesEnumAtom } from '@/store/capitalCounselServices';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import Search from 'antd/es/input/Search';
import { useAtom } from 'jotai';
import React from 'react';

interface SearchProps {
  selectedFilterValue: SelectedCapitalCounselServicesFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedCapitalCounselServicesFilterValue>>;
  getCapitalCounselServicesData: () => void;
}

const CapitalCounselServicesFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getCapitalCounselServicesData,
}: SearchProps) => {
  const [capitalCounselServicesFilterInfo, setCapitalCounselServicesFilterInfo] =
    useAtom(capitalCounselServicesEnumAtom);
  const convertCapitalCounselServicesFilterInfoSelectOptions = (
    capitalCounselServicesFilterInfo: CapitalCounselServicesFilterInfoType,
  ) => {
    return {
      counselStatus: convertToSelectOptions(capitalCounselServicesFilterInfo.counselStatus),
    };
  };

  const selectOptions = convertCapitalCounselServicesFilterInfoSelectOptions(capitalCounselServicesFilterInfo);

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      counselStatus: null,
      name: null,
      phoneNumber: null,
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Search
          className="mr-4"
          placeholder="이름 입력"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.name || ''}
          onChange={(e) => handleInputStringChange('name', e.target.value)}
        />
        <Search
          className="mr-4"
          placeholder="휴대폰 번호 입력"
          allowClear
          style={{ width: 180 }}
          value={selectedFilterValue?.phoneNumber || ''}
          onChange={(e) => handleInputStringChange('phoneNumber', e.target.value)}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="상태"
          optionFilterProp="children"
          options={selectOptions.counselStatus}
          value={selectedFilterValue.counselStatus}
          onChange={(value) => onChangeSelect('counselStatus', value)}
        />
      </div>

      <div className="w-full flex mb-2">
        <div className="w-full"></div>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          className="bg-[#1890ff] mx-2"
          onClick={getCapitalCounselServicesData}
        >
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};
export default CapitalCounselServicesFilterSelector;
