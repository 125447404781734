import { atom } from 'jotai';

export const licenseEnumAtom = atom({
  licenseSalesType: [],
  licenseType: [],
  locate: [],
  status: [],
  useClassification: [],
  licenseCounselStatus: [],
  isCompleted: [],
});
