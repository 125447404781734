import React from 'react';

import BasicInfo from '@/components/Vehicles/Common/Register/BasicInfo';
import { ASSURANCE } from '@/const/vehicles';
import { Tabs } from 'antd';

const AssuranceRegister = () => {
  let tabs: Tab[] = [
    {
      key: 'info',
      label: '차량 정보',
      children: (
        <>
          <BasicInfo salesType={ASSURANCE} />
        </>
      ),
    },
  ];

  return (
    <>
      <h2 className="hidden">차량관리 페이지 - 직트럭 상품용 - 차량 등록</h2>
      <h3 className="text-gray-8">
        차량관리 / 직트럭 상품용 /<span className="font-semibold"> 차량 등록</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default AssuranceRegister;
