import React from 'react';

type ErrorProps = {
  icon: React.ElementType;
  title: string | undefined;
  description: string;
};

const Error = ({ icon: Icon, title, description }: ErrorProps) => {
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Icon sx={{ fontSize: 50, color: '#9CA3AF' }}></Icon>
        <span className="font-semibold text-3xl mt-4">{title}</span>
        <span
          className="text-gray-500 text-lg mt-2 break-keep text-center"
          dangerouslySetInnerHTML={{ __html: description }}
        ></span>
      </div>
    </>
  );
};

export default Error;
