import Error from '@/components/Error/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';

const NotFoundPage = () => {
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Error
          icon={ErrorOutlineIcon}
          title="찾을 수 없는 페이지입니다."
          description="잘못된 링크입니다.<br/>정상적인 링크로<br/>다시 시도해주세요."
        ></Error>
      </div>
    </>
  );
};

export default NotFoundPage;
