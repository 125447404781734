import React, { useState } from 'react';

import apiManager from '@/api/ApiManager';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface ExcelDownloadButtonProps {
  apiPath: string;
  params: ProductExcelParams | MemberExcelParams;
  buttonText: string;
}

const ExcelDownloadButton = ({ apiPath, params, buttonText }: ExcelDownloadButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onClickDownloadExcel = async () => {
    try {
      setIsLoading(true);
      const queryString = Object.keys(params || '')
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(String(params[key])))
        .join('&');

      const response = await apiManager.get(`${apiPath}?${queryString}`);
      const contentDispositionHeader = response.headers['content-disposition'];
      const suggestedFilename = contentDispositionHeader
        ? contentDispositionHeader.split('filename=')[1] || 'example.xlsx'
        : 'example.xlsx';

      const a = document.createElement('a');
      a.href = response.data.url;
      a.download = suggestedFilename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      className="mr-2"
      icon={<DownloadOutlined />}
      style={{ borderColor: '#237804', color: '#237804' }}
      onClick={onClickDownloadExcel}
      loading={isLoading}
    >
      {buttonText}
    </Button>
  );
};

export default ExcelDownloadButton;
