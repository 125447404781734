// atoms.ts
import { atom, useSetAtom } from 'jotai';

interface Member {
  loginId: string;
  id: number;
  name: string;
  phoneNumber: string;
}

export const memberAtom = atom<Member>({
  loginId: '',
  id: 0,
  name: '',
  phoneNumber: '',
});

export function useSetMemberAtom() {
  return useSetAtom(memberAtom);
}
