import { putDashboardAppDownloadCount } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE, NO_REQUIRED_VALUE_ERROR_MESSAGE } from '@/const/errorMessage';
import { Button, DatePicker, DatePickerProps, Input, Modal, message } from 'antd';
import ko from 'antd/es/date-picker/locale/ko_KR';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

interface DashboardSearchResultProps {
  data: DashboardReportResponse | null;
  getDashboardData: () => void;
}
const DashboardSearchResult = ({ data, getDashboardData }: DashboardSearchResultProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const openModal = () => {
    setIsOpenModal(true);
  };

  const [selectedValue, setSelectedValue] = useState<DashboardAppDownloadCountCreateOrUpdateRequest>({
    baseDay: '',
    appDownloadCount: '',
  });

  const onChange: DatePickerProps['onChange'] = (_, dateStr) => {
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      baseDay: dateStr,
    }));
  };
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      appDownloadCount: value,
    }));
  };

  const validRequest = () => {
    return true;
  };

  const onClickModal = () => {
    if (validRequest()) {
      const request = {
        baseDay: selectedValue.baseDay,
        appDownloadCount: selectedValue.appDownloadCount,
      };
      putDashboardAppDownloadCountMutate.mutate(request);
    } else {
      message.error(NO_REQUIRED_VALUE_ERROR_MESSAGE, 2);
    }
  };

  const putDashboardAppDownloadCountMutate = useMutation(
    (requestData: { baseDay: string; appDownloadCount: string }) => putDashboardAppDownloadCount(requestData),
    {
      onSuccess: ({ data }) => {
        message.success('앱 다운로드 수가 등록되었습니다.', 2);
        setSelectedValue({ baseDay: '', appDownloadCount: '' });
        setIsOpenModal(false);
        getDashboardData();
      },
      onError: () => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  return (
    <>
      <div className="py-2 px-4">
        <h1 className="font-bold text-lg mb-4">대시보드</h1>
        <div className="flex gap-4 justify-between mb-4">
          <div className="p-2 border-gray-300 p-2 border rounded-lg flex-1 mr-1">
            <h2 className="font-bold">신규회원수</h2>
            <p>{data?.newMemberCount}</p>
          </div>
          <div className="p-2 border border-gray-300 rounded-lg flex-1" onClick={openModal}>
            <h2 className="font-bold">신규 앱 다운로드 수</h2>
            <p>{data?.todayDownloadAppCount}</p>
          </div>
        </div>
      </div>
      <Modal title="신규 앱 다운로드 수 입력" open={isOpenModal} footer={null} onCancel={onCloseModal} centered>
        <div style={{ textAlign: 'center' }}>
          <div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>날짜:
              </span>
              <DatePicker
                className="mr-4 my-2"
                style={{ width: 200 }}
                locale={ko}
                onChange={onChange}
                format="YYYY-MM-DD"
              />
            </div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>다운로드 수:{' '}
              </span>
              <Input
                type="number"
                inputMode="numeric"
                placeholder="다운로드 수"
                name="downloadCount"
                value={selectedValue?.appDownloadCount}
                onChange={onChangeInput}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <Button className="w-[90px]" onClick={() => onCloseModal()}>
              닫기
            </Button>
            <Button type="primary" onClick={onClickModal} className="w-[90px] bg-[#1890ff] mx-2">
              완료
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DashboardSearchResult;
