import { Checkbox, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';

import type { GetProp } from 'antd';

interface CheckBoxComponentProps {
  selectedList: string[];
  setSelectedList: React.Dispatch<React.SetStateAction<string[]>>;
  optionList: EnumPresenter[];
  isDisabled?: boolean;
  setIsDisabledEditBtn?: React.Dispatch<React.SetStateAction<boolean>>;
}

type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];

const CheckBoxComponent = ({
  optionList,
  selectedList,
  setSelectedList,
  isDisabled,
  setIsDisabledEditBtn,
}: CheckBoxComponentProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);

  useEffect(() => {
    setCheckedValues(selectedList);
  }, [selectedList]);

  const onChange = (list: CheckboxValueType[]) => {
    if (setIsDisabledEditBtn) {
      setIsDisabledEditBtn(false);
    }
    const stringList: string[] = list.map(String);
    setSelectedList(stringList);
  };

  return (
    <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={checkedValues}>
      <Row>
        {optionList?.map((item: any, index: number) => (
          <Col span={8} key={index}>
            <Checkbox value={item.code} disabled={isDisabled || false} checked={checkedValues?.includes(item.code)}>
              <span className="text-black">{item.desc}</span>
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

export default CheckBoxComponent;
