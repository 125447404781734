import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';

type LoadingSpinnerType = {
  spinning: boolean;
  text: string;
  isTextChanging?: boolean;
};

const LoadingSpinner = ({ spinning, text, isTextChanging }: LoadingSpinnerType) => {
  const [displayText, setDisplayText] = useState(text);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // TODO: 시간별로 텍스트 변경 필요시 해당 내용 추가
    }, 6000);

    return () => clearInterval(intervalId);
  }, [displayText]);

  return (
    <>
      {spinning && (
        <>
          <div className="fixed bg-black opacity-75 w-full h-full justify-center items-center z-40 top-0 left-0 inline-flex "></div>
          <div className="fixed inline-flex flex-col w-full h-full justify-center items-center z-40 top-0 left-0">
            <Spin size="large" />
            <div className="text-white mt-4"> {isTextChanging ? displayText : text}</div>
          </div>
        </>
      )}
    </>
  );
};

export default LoadingSpinner;
