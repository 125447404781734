import { productInquiryEnumAtom } from '@/store/productInquiry';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React from 'react';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  selectedFilterValue: SelectedProductInquiryFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedProductInquiryFilterValue>>;
  getProductInquiryData: () => void;
}

const ProductInquiryFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getProductInquiryData,
}: SearchProps) => {
  const [productInquiryFilterInfo, setProductInquiryFilterInfo] = useAtom(productInquiryEnumAtom);

  const convertProductInquiryFilterInfoSelectOptions = (productInquiryFilterInfo: ProductInquiryFilterInfoType) => {
    return {
      status: convertToSelectOptions(productInquiryFilterInfo.status),
    };
  };

  const selectOptions = convertProductInquiryFilterInfoSelectOptions(productInquiryFilterInfo);
  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      status: null,
      name: null,
      phoneNumber: null,
      startDate: null,
      endDate: null,
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Search
          className="mr-4"
          placeholder="신청자명"
          allowClear
          onSearch={getProductInquiryData}
          style={{ width: 180 }}
          value={selectedFilterValue?.name || ''}
          onChange={(e) => handleInputStringChange('name', e.target.value)}
        />

        <Search
          className="mr-4"
          placeholder="전화번호 입력"
          allowClear
          onSearch={getProductInquiryData}
          style={{ width: 180 }}
          value={selectedFilterValue?.phoneNumber || ''}
          onChange={(e) => handleInputStringChange('phoneNumber', e.target.value)}
        />

        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="상태"
          optionFilterProp="children"
          options={selectOptions.status}
          value={selectedFilterValue.status}
          onChange={(value) => onChangeSelect('status', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <div className="w-full">
          <span className="mr-2">요청일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getProductInquiryData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default ProductInquiryFilterSelector;
