import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const { Search } = Input;
const { RangePicker } = DatePicker;

interface SearchProps {
  selectedFilterValue: AdminMemberSelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<AdminMemberSelectedFilterValue>>;
  getAdminMemberData: () => void;
}

const AdminMemberFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getAdminMemberData,
}: SearchProps) => {
  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      name: '',
      phoneNumber: '',
      startDate: '',
      endDate: '',
      ascending: false,
    });
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };
  return (
    <div className="py-4">
      <div className="mb-4 flex justify-between">
        <div className="">
          <Search
            className="mr-4"
            placeholder="이름 입력"
            allowClear
            onSearch={getAdminMemberData}
            style={{ width: 180 }}
            value={selectedFilterValue?.name || ''}
            onChange={(e) => handleInputStringChange('name', e.target.value)}
          />
          <Search
            className="mr-4"
            placeholder="휴대폰 번호 입력"
            allowClear
            onSearch={getAdminMemberData}
            style={{ width: 180 }}
            value={selectedFilterValue?.phoneNumber || ''}
            onChange={(e) => handleInputStringChange('phoneNumber', e.target.value)}
          />
          <span className="mr-2">가입일시</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.startDate ? dayjs(selectedFilterValue.startDate) : null,
              selectedFilterValue.endDate ? dayjs(selectedFilterValue.endDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>
        <div>
          <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getAdminMemberData}>
            검색
          </Button>
          <Button onClick={clear}>초기화</Button>
        </div>
      </div>
    </div>
  );
};

export default AdminMemberFilterSelector;
