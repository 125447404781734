import BannerForm from '@/components/Banner/BannerForm';
import React from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';

const BannerRegister = () => {
  const { id } = useParams();
  let tabs: Tab[] = [
    {
      key: 'info',
      label: '배너 정보',
      children: <BannerForm />,
    },
  ];
  return (
    <>
      <h3 className="text-gray-8">
        배너관리 /<span className="font-semibold"> 배너 {id ? '수정' : '등록'}</span>
      </h3>
      <div className="py-4">
        <Tabs defaultActiveKey="info" items={tabs}></Tabs>
      </div>
    </>
  );
};

export default BannerRegister;
