import React from 'react';

const SvgLogo = ({ color }: svgIconProps) => (
  <svg width={46} height={22} viewBox="0 0 46 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4427_20755)">
      <path
        d="M0.897089 3.60059V5.88216H4.19449C4.19449 5.88216 3.63684 8.44586 0 10.7765L1.45474 12.6287C1.45474 12.6287 2.75188 12.2117 4.78851 9.66025L7.21307 12.1013L8.92239 10.4238L6.25537 7.59947C6.25537 7.59947 7.16458 6.07842 7.16458 3.60059L0.897089 3.60059Z"
        fill={color}
      />
      <path
        d="M4.80664 14.4746V16.793H10.5407C10.5407 16.793 10.5135 19.0562 9.14055 20.6661L11.2499 22.0001C11.2499 22.0001 13.2684 19.6081 13.1411 14.4746H4.80664Z"
        fill={color}
      />
      <path
        d="M10.8496 12.6284H13.3227V2.66183C13.3227 2.66183 13.3712 2.30917 13.7288 2.20184H43.072C43.072 2.20184 43.4539 2.2325 43.5509 2.61277V7.25257H40.9945V9.12475H43.5509V12.8231H45.9997V0.932257C45.9997 0.932257 45.9209 0.1656 45.1935 0H11.7588C11.7588 0 11.0193 0.0735967 10.8496 0.969056V12.6284Z"
        fill={color}
      />
      <path
        d="M23.6699 3.34277H31.7073V5.49555H25.9429V7.07793H31.7073V9.0651H25.9672V10.8131H31.7073V12.8233H23.6699V3.34277Z"
        fill={color}
      />
      <path
        d="M33.3076 3.34277V5.47715H37.4415V7.0718H33.3076V12.8233H39.8903V10.8131H35.6352V9.1019H39.8903V3.34277H33.3076Z"
        fill={color}
      />
      <path
        d="M15.0811 14.4746V16.8052H43.3818C43.3818 16.8052 43.4636 18.8538 41.9725 20.6569L44.0728 21.9817C44.0728 21.9817 46.0003 19.9639 46.0003 14.4746L15.0811 14.4746Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4427_20755">
        <rect width={46} height={22} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLogo;
