import { getMonthAndWeekFromWeekOfYear } from '@/utils/common';
import { DualAxes } from '@ant-design/plots';
import React, { useEffect, useState } from 'react';

interface LineChartProps {
  chartData: DashboardChartResponse[] | undefined;
}

const DualAxesLineChart = ({ chartData }: LineChartProps) => {
  const [data, setData] = useState<any[]>([]);

  const transformData = (data: DashboardChartResponse[]) => {
    return data.map((item, index) => {
      return {
        baseDay: item.baseDay.includes('-') ? item.baseDay : getMonthAndWeekFromWeekOfYear(Number(item.baseDay)),
        '앱 다운로드수': item.totalAppDownloadCount,
        '총 회원 수': item.totalMemberCount,
        '판매 상품': item.saleProductCount,
        '판매 완료': item.completedProductCount,
      };
    });
  };

  useEffect(() => {
    if (chartData) {
      setData(transformData(chartData || []));
    }
  }, [chartData]);

  const config = {
    data,
    scale: { color: { range: ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#6F5EF9'] } },
    xField: 'baseDay',
    legend: {
      color: {
        itemMarker: 'circle',
      },
    },
    children: [
      {
        type: 'line',
        yField: '앱 다운로드수',
        color: '#5B8FF9',
        lineStyle: { lineWidth: 2 },
        axis: {
          y: {
            position: 'left',
            style: { titleFill: '#FAAD14' },
          },
        },
      },
      {
        type: 'line',
        yField: '총 회원 수',
        color: '#FAAD14',
        lineStyle: { lineWidth: 2 },
        axis: {
          y: {
            position: 'left',
            style: { titleFill: '#FAAD14' },
          },
        },
      },
      {
        type: 'line',
        yField: '판매 상품',
        color: '#5AD8A6',
        lineStyle: { lineWidth: 2 },
        axis: {
          y: {
            position: 'right',
            style: { titleFill: '#5AD8A6' },
          },
        },
      },
      {
        type: 'line',
        yField: '판매 완료',
        color: '#30BF78',
        lineStyle: { lineWidth: 2 },
        axis: {
          y: {
            position: 'right',
            style: { titleFill: '#30BF78' },
          },
        },
      },
    ],
  };

  return <DualAxes {...config} />;
};

export default DualAxesLineChart;
