import { getAppDownloadCount } from '@/api/public';
import ExcelUploader from '@/components/Common/ExcelUploader';
import AppDownloadCountFilterSelector from '@/components/Dashboard/AppDownloadCountFilterSelector';
import AppDownloadCountSearchResult from '@/components/Dashboard/AppDownloadCountSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const AppDownloadCountList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [appDownloadSearchResult, setAppDownloadSearchResult] = useState<DashboardPagingResponse | null>(null);
  const [selectedFilterValue, setSelectedFilterValue] = useState<DashboardSelectedFilterValue>({
    startDate: '',
    endDate: '',
  });

  const getAppDownloadCountData = async (page?: number) => {
    const { startDate, endDate } = selectedFilterValue;
    const queryParams = new URLSearchParams({
      startDate,
      endDate,
      page: String(page ? page : 1),
      size: '10',
    });
    const response = await getAppDownloadCount(queryParams);
    setAppDownloadSearchResult(response.data);
  };

  useEffect(() => {
    getAppDownloadCountData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <AppDownloadCountFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getAppDownloadCountData={() => getAppDownloadCountData(currentPage)}
      />
      <ExcelUploader
        path="/admin/v1/dashboard/app-download-count/excel"
        afterUploadRunningMethod={() => getAppDownloadCountData(currentPage)}
      ></ExcelUploader>
      <AppDownloadCountSearchResult
        selectedFilterValue={selectedFilterValue}
        data={appDownloadSearchResult}
        getDashboardData={() => getAppDownloadCountData(currentPage)}
      />
      <Pagination
        current={currentPage}
        total={appDownloadSearchResult?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default AppDownloadCountList;
