import { Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';

interface DataType {
  id: number;
  key: number;
  tons: number;
  year: number;
  modelName: string;
  loaded: string;
  loadedInnerLength: number;
  axis: string;
  price: number;
}

const PricesSearchResult = ({ data }: PricesResponseDataProps) => {
  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      tons: item.pricesStandard.tons,
      year: item.pricesStandard.year,
      modelName: item.pricesStandard.model.name,
      loaded: item.pricesStandard.loaded.desc,
      loadedInnerLength: item.pricesStandard.loadedInnerLength,
      axis: item.pricesStandard.axis.desc,
      price: item.price,
    })) || [];
  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'id',
    },
    {
      title: '모델명',
      dataIndex: 'modelName',
    },
    {
      title: '톤수',
      dataIndex: 'tons',
    },
    {
      title: '연식',
      dataIndex: 'year',
    },
    {
      title: '가변축',
      dataIndex: 'axis',
    },
    {
      title: '적재함 종류',
      dataIndex: 'loaded',
    },
    {
      title: '적재함 길이(m)',
      dataIndex: 'loadedInnerLength',
    },
    {
      title: '시세금액(만원)',
      dataIndex: 'price',
    },
  ];

  return (
    <>
      <div className="py-4">
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </>
  );
};

export default PricesSearchResult;
