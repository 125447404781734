import { patchOneStopServicesComment, patchOneStopServicesCompleted } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { oneStopServicesEnumAtom } from '@/store/oneStopServices';
import { convertToSelectOptions } from '@/utils/common';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Select, Table, TableColumnsType, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { TableRowSelection } from 'antd/es/table/interface';
import { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import BasicPopup from '../Common/BasicPopup';
import SortingButton from '../Common/SortingButton';

interface OneStopServicesResponseDataProps {
  selectedFilterValue: SelectedOneStopServicesFilterValue;
  data: OneStopServicesResponse | null;
  currentPage: number;
  getOneStopServicesData: () => void;
  ascending: boolean;
  updateAscending: () => void;
}

interface StatusChangeRequest {
  id: string;
  status: string;
}

interface DataType {
  id: number;
  key: number;
  index: number;
  status?: EnumPresenter;
  name?: string;
  phoneNumber?: string;
  comment?: string;
  createdDate?: string;
}

const OneStopServicesSearchResult = ({
  selectedFilterValue,
  data,
  currentPage,
  getOneStopServicesData,
  ascending,
  updateAscending,
}: OneStopServicesResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '요청자',
      dataIndex: 'name',
    },
    {
      title: '전화번호',
      dataIndex: 'phoneNumber',
    },
    {
      title: '상태',
      dataIndex: 'status',
      render: (text, record) => (
        <Select
          value={record.status?.desc}
          options={selectOptions.status}
          style={{ width: 90 }}
          onChange={(value) => handleStatusChange(value, record)}
        ></Select>
      ),
    },
    {
      title: <SortingButton title="요청일자" isAscending={ascending} onclick={updateAscending} />,
      dataIndex: 'createdDate',
    },
    {
      title: '메모',
      dataIndex: 'memo',
      render: (text, record) => (
        <>
          {record.comment ? (
            <>
              <p
                className="font-bold mr-3 overflow-hidden whitespace-no-wrap text-ellipsis break-all line-clamp-1"
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                }}
              >
                {record.comment}
              </p>

              <Button onClick={() => handleMemoButtonClick(record)}>수정</Button>
            </>
          ) : (
            <Button onClick={() => handleMemoButtonClick(record)}>작성</Button>
          )}
        </>
      ),
    },
  ];

  const handleStatusChange = (value: string, record: DataType) => {
    setIsOpenPopup(true);
    const request = {
      id: String(record.id),
      status: value,
    };
    setStatusRequest(request);
  };

  const onClickStatusChangeButton = () => {
    patchOneStopServicesCompletedMutation.mutate(statusRequest);
  };
  const onClickCancelButton = () => {
    setIsOpenPopup(false);
    setStatusRequest({ id: '', status: '' });
  };

  const [oneStopServicesFilterInfo, setOneStopServicesFilterInfo] = useAtom(oneStopServicesEnumAtom);

  const convertOneStopServicesFilterInfoToSelectOptions = (
    oneStopServicesFilterInfo: OneStopServicesFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(oneStopServicesFilterInfo.status),
    };
  };

  const selectOptions = convertOneStopServicesFilterInfoToSelectOptions(oneStopServicesFilterInfo);
  const [isMemoModalVisible, setIsMemoModalVisible] = useState(false);
  const [selectedMemoId, setSelectedMemoId] = useState(0);
  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
  const [memoContent, setMemoContent] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [statusRequest, setStatusRequest] = useState<StatusChangeRequest>({ id: '', status: '' });

  const handleMemoButtonClick = (oneStopServicesInfo: any) => {
    setIsMemoModalVisible(true);
    setSelectedMemoId(oneStopServicesInfo.id);
    setIsDisabledEditBtn(true);
    setMemoContent(oneStopServicesInfo.comment);
  };

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      name: item.name || '-',
      phoneNumber: item.phoneNumber || '-',
      status: item.status || null,
      comment: item.comment || '',
      createdDate: item.createdDate || '-',
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);
  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const patchOneStopServicesCompletedMutation = useMutation(
    (requestData: { id: string; status: string }) => patchOneStopServicesCompleted(requestData.id, requestData),
    {
      onSuccess: () => {
        message.success('상태가 변경되었습니다.', 2);

        getOneStopServicesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
      onSettled: () => {
        setIsOpenPopup(false);
        setStatusRequest({ id: '', status: '' });
      },
    },
  );

  const handleMemoModalClose = () => {
    setIsMemoModalVisible(false);
    setMemoContent('');
  };

  const handleMemoSave = () => {
    handleMemoModalClose();
    const request = {
      comment: memoContent,
    };
    patchOneStopServicesCommentMutation.mutate(request);
  };

  const patchOneStopServicesCommentMutation = useMutation(
    (requestData: { comment: string }) => patchOneStopServicesComment(String(selectedMemoId), requestData),
    {
      onSuccess: () => {
        message.success('상세 정보가 수정되었습니다.', 2);
        setIsDisabledEditBtn(true);
        getOneStopServicesData();
      },
      onError: (error: AxiosError) => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const onChangeMemo = (memo: string) => {
    setMemoContent(memo);
    setIsDisabledEditBtn(false);
  };

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 요청건수</span> {data?.totalElements}개
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}대 선택됨` : ''}</span>
          </div>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={tableData} pagination={false} />
      </div>

      <Modal
        title="메모 보기"
        open={isMemoModalVisible}
        onCancel={handleMemoModalClose}
        footer={[
          <Button key="close" onClick={handleMemoModalClose}>
            닫기
          </Button>,
          <Button
            key="save"
            type="primary"
            className="bg-[#1890ff] mx-2"
            onClick={handleMemoSave}
            disabled={isDisabledEditBtn}
          >
            저장
          </Button>,
        ]}
      >
        <TextArea
          value={memoContent}
          onChange={(e) => onChangeMemo(e.target.value)}
          placeholder="메모 내용 입력"
          autoSize={{ minRows: 5 }}
        />
      </Modal>
      <BasicPopup
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="상태를 변경하겠습니까?"
        content=""
        handleOk={() => onClickStatusChangeButton()}
        handleCancel={() => onClickCancelButton()}
      ></BasicPopup>
    </>
  );
};

export default OneStopServicesSearchResult;
