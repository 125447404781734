import { productsEnumAtom } from '@/store/products';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { Button, InputNumber, Select } from 'antd';
import { useAtom } from 'jotai';
import React, { useState } from 'react';

interface SearchProps {
  selectedFilterValue: PricesFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedFilterValue>>;
  getPricesData: () => void;
}

const PricesFilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getPricesData }: SearchProps) => {
  const [productFilterInfo, setProductFilterInfo] = useAtom(productsEnumAtom);
  const [selectedManufacturerCategoryId, setSelectedManufacturerCategoryId] = useState<string>('');

  const convertManufacturerCategoriesToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
  ): { value: string; label: string }[] => {
    return manufacturerCategoriesWithModels.map((item) => ({
      value: item.manufacturerCategories.id.toString(),
      label: item.manufacturerCategories.name,
    }));
  };

  const convertModelsToSelectOptions = (
    manufacturerCategoriesWithModels: ManufacturerCategoriesWithModels[],
    selectedManufacturerCategoryId: string,
  ): { value: string; label: string }[] => {
    const selectedManufacturerCategory = manufacturerCategoriesWithModels.find(
      (item) => item.manufacturerCategories.id.toString() === selectedManufacturerCategoryId,
    );

    return selectedManufacturerCategory
      ? selectedManufacturerCategory.model.map((model) => ({
          value: model.id.toString(),
          label: model.name,
        }))
      : [];
  };

  const convertProductsFilterInfoToSelectOptions = (productsFilterInfo: ProductsFilterInfoType) => {
    return {
      axis: convertToSelectOptions(productsFilterInfo.axis),
      loaded: convertToSelectOptions(productsFilterInfo.loaded),
      manufacturerCategories: convertManufacturerCategoriesToSelectOptions(
        productsFilterInfo.manufacturerCategoriesWithModels,
      ),
      model: convertModelsToSelectOptions(
        productsFilterInfo.manufacturerCategoriesWithModels,
        selectedManufacturerCategoryId,
      ),
    };
  };

  const selectOptions = convertProductsFilterInfoToSelectOptions(productFilterInfo);

  const convertModelsOptions = convertModelsToSelectOptions(
    productFilterInfo.manufacturerCategoriesWithModels,
    selectedManufacturerCategoryId,
  );

  const onChangeSelect = (name: string, value: string) => {
    if (name === 'manufacturerCategories') {
      setSelectedManufacturerCategoryId(value);
      setSelectedFilterValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
        model: null,
      }));
    } else {
      setSelectedFilterValue((prevOptions) => ({
        ...prevOptions,
        [name]: value,
      }));
    }
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleInputNumberChange = (name: string, value: number | null) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      productNumber: null,
      truckName: null,
      truckNumber: null,
      status: null,
      productsType: null,
      manufacturerCategories: null,
      model: null,
      tons: null,
      loaded: null,
      loadedInnerLength: null,
      axis: null,
      transmission: null,
      startDate: null,
      endDate: null,
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="제조사"
          optionFilterProp="children"
          options={selectOptions.manufacturerCategories}
          value={selectedFilterValue.manufacturerCategories}
          onChange={(value) => onChangeSelect('manufacturerCategories', value)}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="모델"
          optionFilterProp="children"
          options={convertModelsOptions}
          value={selectedFilterValue.model}
          onChange={(value) => onChangeSelect('model', value)}
        />
        <InputNumber
          className="mr-4 my-2"
          placeholder="톤수"
          style={{ width: 100 }}
          value={selectedFilterValue?.tons}
          onChange={(value) => handleInputNumberChange('tons', value)}
          suffix="t"
          wheel={false}
        />
        <InputNumber
          className="mr-4 my-2"
          placeholder="연식 입력"
          style={{ width: 180 }}
          value={selectedFilterValue?.year}
          onChange={(value) => handleInputNumberChange('year', Number(value))}
          wheel={false}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="적재함 종류"
          optionFilterProp="children"
          options={selectOptions.loaded}
          value={selectedFilterValue.loaded}
          onChange={(value) => onChangeSelect('loaded', value)}
        />
        <InputNumber
          className="mr-4 my-2"
          placeholder="적재함 길이"
          style={{ width: 180 }}
          value={selectedFilterValue?.loadedInnerLength}
          onChange={(value) => handleInputNumberChange('loadedInnerLength', value)}
          suffix="m"
          wheel={false}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="가변축"
          optionFilterProp="children"
          options={selectOptions.axis}
          value={selectedFilterValue.axis}
          onChange={(value) => onChangeSelect('axis', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <div className="w-full"></div>
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getPricesData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default PricesFilterSelector;
