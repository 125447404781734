import { getCapitalCounselServices } from '@/api/public';
import CapitalCounselServicesFilterSelector from '@/components/CapitalCounselServices/CapitalCounselServicesFilterSelector';
import CapitalCounselServicesSearchResult from '@/components/CapitalCounselServices/CapitalCounselServicesSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const CapitalCounselServicesList = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedCapitalCounselServicesFilterValue>({
    counselStatus: null,
    name: null,
    phoneNumber: null,
  });

  const [searchResultData, setSearchResultData] = useState<CapitalCounselServicesResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getCapitalCounselServicesData = async (page?: number) => {
    const { counselStatus, name, phoneNumber } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      counselStatus: counselStatus || '',
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getCapitalCounselServices(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getCapitalCounselServicesData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h2 className="hidden">대출상담 서비스 관리 페이지 - 신청 내역</h2>
      <h3 className="text-gray-8">
        부가 서비스 관리 / <span className="font-semibold">대출상담 서비스 관리</span>
      </h3>

      <CapitalCounselServicesFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getCapitalCounselServicesData={() => getCapitalCounselServicesData(currentPage)}
      />
      <CapitalCounselServicesSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getCapitalCounselServicesData={() => getCapitalCounselServicesData(currentPage)}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default CapitalCounselServicesList;
