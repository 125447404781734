import { getProductInquiry } from '@/api/public';
import ProductInquiryFilterSelector from '@/components/ProductInquiry/ProductInquiryFilterSelector';
import ProductInquirySearchResult from '@/components/ProductInquiry/ProductInquirySearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';

const ProductInquiryListAll = () => {
  const [selectedFilterValue, setSelectedFilterValue] = useState<SelectedProductInquiryFilterValue>({
    status: null,
    name: null,
    phoneNumber: null,
    startDate: null,
    endDate: null,
    ascending: false,
  });

  const [searchResultData, setSearchResultData] = useState<ProductInquiryResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const getProductInquiryData = async (page?: number) => {
    const { status, name, phoneNumber, startDate, endDate, ascending } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      name: name || '',
      status: status || '',
      phoneNumber: phoneNumber || '',
      startDate: startDate || '',
      endDate: endDate || '',
      ascending: String(ascending),
      page: String(page ? page : 1),
      size: '10',
    });
    const response = await getProductInquiry(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getProductInquiryData(currentPage);
  }, [currentPage, selectedFilterValue.ascending]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const updateAscending = () => {
    setSelectedFilterValue({ ...selectedFilterValue, ascending: !selectedFilterValue.ascending });
  };

  return (
    <>
      <h2 className="hidden">차량 구매문의 관리 페이지 - 신청 내역</h2>
      <h3 className="text-gray-8">차량 구매문의 관리</h3>

      <ProductInquiryFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getProductInquiryData={() => getProductInquiryData(currentPage)}
      ></ProductInquiryFilterSelector>

      <ProductInquirySearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        currentPage={currentPage}
        getProductInquiryData={() => getProductInquiryData(currentPage)}
        ascending={selectedFilterValue.ascending}
        updateAscending={updateAscending}
      ></ProductInquirySearchResult>
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default ProductInquiryListAll;
