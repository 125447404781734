import { deleteJob } from '@/api/public';
import { Button, Table, TableColumnsType, message } from 'antd';
import React, { useEffect, useState } from 'react';

import BasicPopup from '@/components/Common/BasicPopup';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { TableRowSelection } from 'antd/es/table/interface';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface DataType {
  id: number;
  key: React.Key;
  createdDate: string;
  modifiedDate: string;
  detailContents: string;
  loadedInnerLength: number;
  maxTons: number;
  minTons: number;
  period: string;
  salary: number;
  salaryType: string;
  status: string;
  title: string;
  transportItem: string;
  transportSection: string;
  workingArea: string;
  workingDays: string;
  workingEndHour: number;
  workingHours: string;
  workingStartHour: number;
}

const SearchResult = ({ data, currentPage, getJobData }: JobResponseDataProps) => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenPoppup, setIsOpenPopup] = useState(false);

  const columns: TableColumnsType<DataType> = [
    {
      title: '번호',
      dataIndex: 'index',
    },
    {
      title: '제목',
      dataIndex: 'title',
    },
    {
      title: '최소 톤수',
      dataIndex: 'minTons',
      render: (text, record) => {
        return `${text}t`;
      },
    },
    {
      title: '최대 톤수',
      dataIndex: 'maxTons',
      render: (text, record) => {
        return `${text}t`;
      },
    },
    {
      title: '적재함 길이',
      dataIndex: 'loadedInnerLength',
      render: (text, record) => {
        return `${text}m`;
      },
    },
    {
      title: '운송 물품',
      dataIndex: 'transportItem',
    },
    {
      title: '운송 구간',
      dataIndex: 'workingArea',
    },
    {
      title: '근무 요일',
      dataIndex: 'workingDays',
    },
    {
      title: '근무 시간',
      dataIndex: 'workingHour',
      render: (text, record) => {
        return `${record.workingStartHour}시 ~ ${record.workingEndHour}시`;
      },
    },
    {
      title: '급여',
      dataIndex: 'salary',
      render: (text, record) => {
        return `${text}만원`;
      },
    },
    {
      title: '급여 타입',
      dataIndex: 'salaryType',
    },
    {
      title: '상태',
      dataIndex: 'status',
    },
  ];

  const updatedTableData =
    data?.data?.map((item, index) => ({
      id: item.id,
      key: item.id,
      index: (currentPage - 1) * 10 + index + 1,
      createdDate: item.createdDate,
      modifiedDate: item.modifiedDate,
      detailContents: item.detailContents,
      loadedInnerLength: item.loadedInnerLength,
      maxTons: item.maxTons,
      minTons: item.minTons,
      period: item.period?.desc,
      salary: item.salary,
      salaryType: item.salaryType?.desc,
      status: item.status?.desc,
      title: item.title,
      transportItem: item.transportItem,
      transportSection: item.transportSection,
      workingArea: item.workingArea?.desc,
      workingDays: item.workingDays?.desc,
      workingEndHour: item.workingEndHour,
      workingHours: item.workingHours?.desc,
      workingStartHour: item.workingStartHour,
    })) || [];

  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onClickDelete = () => {
    setIsOpenPopup(false);

    if (selectedRowKeys.length > 0) {
      const [firstSelectedId] = selectedRowKeys;
      deleteJobMutation.mutate(String(firstSelectedId));
      getJobData();
    } else {
      message.error('일자리가 선택되지 않았어요. 삭제할 일자리를 선택해주세요.');
    }
  };

  const deleteJobMutation = useMutation(deleteJob, {
    onSuccess: () => {
      message.success('삭제되었습니다.', 2);
      getJobData();
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const hasSelected = selectedRowKeys.length > 0;

  const showConfirm = () => {
    setIsOpenPopup(true);
  };
  const onMoveRegisterPage = () => {
    navigate('/job/form');
  };

  const rowProps = (record: DataType) => ({
    onClick: () => {
      navigate(`/job/${record.id}`);
    },
  });

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">전체 일자리</span> {data?.totalElements}개
            <span style={{ marginLeft: 8 }}>{hasSelected ? ` ${selectedRowKeys.length}개 선택됨` : ''}</span>
          </div>
          <div>
            <Button className="mr-2" onClick={onMoveRegisterPage} style={{ borderColor: '#1890ff', color: '#1890ff' }}>
              등록
            </Button>
            <Button danger onClick={showConfirm}>
              삭제
            </Button>
          </div>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={false}
          onRow={rowProps}
        />
      </div>
      <BasicPopup
        isOpen={isOpenPoppup}
        setIsOpen={setIsOpenPopup}
        icon={<ExclamationCircleFilled style={{ color: '#faad14', marginRight: '8px' }}></ExclamationCircleFilled>}
        title="정말 삭제하시겠어요?"
        content=""
        handleOk={() => onClickDelete()}
        handleCancel={() => setIsOpenPopup(false)}
      ></BasicPopup>
    </>
  );
};

export default SearchResult;
