import apiManager from '@/api/ApiManager';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';

interface TinyMCEEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const TinyMCEEditor = ({ value, onChange }: TinyMCEEditorProps) => {
  const [editorContent, setEditorContent] = useState<string>(value);

  useEffect(() => {
    setEditorContent(value);
  }, [value]);

  const handleEditorChange = (content: string) => {
    setEditorContent(content);
    onChange(content);
  };

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append('uploadFile', file);

    try {
      const path = '/admin/v1/notice/upload';
      const response = await apiManager.post(path, formData, {
        headers: {
          'Content-Type': 'MULTIPART_FORM_DATA',
        },
      });

      return response.data.url;
    } catch (error) {
      console.error('Image upload failed:', error);
      return '';
    }
  };

  return (
    <div>
      <Editor
        apiKey="3zy3c1xg0d4ufkvtg8tjcti1zd4yrxt6f87fkdhrpv0nqxtu"
        value={editorContent}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'image',
            'table',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | table | image',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          automatic_uploads: false,
          file_picker_types: 'image',
          file_picker_callback: function (callback, value, meta) {
            if (meta.filetype === 'image') {
              const input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.onchange = async function () {
                const file = (input.files as FileList)[0];

                const imageUrl = await uploadImage(file);

                if (imageUrl) {
                  callback(imageUrl, { title: file.name });
                } else {
                  console.error('Image upload failed');
                }
              };
              input.click();
            }
          },
        }}
        onEditorChange={handleEditorChange}
      />
    </div>
  );
};

export default TinyMCEEditor;
