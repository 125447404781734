import { Button, Input, Layout, Modal, Tooltip, message, theme } from 'antd';
import { useAtom, useSetAtom } from 'jotai';

import { postLogout, updateAdminMember } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE, NOT_EQUAL_PASSWORD_AND_CONFIRM } from '@/const/errorMessage';
import { memberAtom } from '@/store/member';
import { PoweroffOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

const HeaderComponent = () => {
  const setMemberDataAtom = useSetAtom(memberAtom);
  const [memberData] = useAtom(memberAtom);
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [password, setPassword] = useState({
    password: '',
    confirmPassword: '',
  });
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const logoutMutation = useMutation(postLogout, {
    onSuccess: () => {
      localStorage.removeItem('token');
      message.success('로그아웃 되었습니다.', 2);
      navigate('/login');
      setMemberDataAtom({ loginId: '', id: 0, name: '', phoneNumber: '' });
    },
    onError: (error: AxiosError) => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const onClickLogout = () => {
    logoutMutation.mutate({ memberId: memberData.id });
  };

  const onClickPasswordChange = () => {
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
    setPassword({ password: '', confirmPassword: '' });
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };

  const onClickChangePassword = () => {
    if (validatePassword()) {
      const request = {
        password: password.password,
      };
      patchAdminMemberMutation.mutate(request);
    } else {
      message.error(NOT_EQUAL_PASSWORD_AND_CONFIRM, 2);
    }
  };

  const patchAdminMemberMutation = useMutation(
    (requestData: { password: string }) => updateAdminMember(memberData.id.toString(), requestData),
    {
      onSuccess: ({ data }) => {
        message.success('비밀번호가 변경되었어요.', 2);
        setPassword({ password: '', confirmPassword: '' });
        setIsOpenModal(false);
      },
      onError: () => {
        message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
      },
    },
  );

  const validatePassword = () => {
    if (password.password == password.confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Header className="sticky top-0 right-0 z-10 h-[60px] p-0" style={{ background: colorBgContainer }}>
        <div className="flex justify-end items-center px-4">
          <div className="mx-4">
            안녕하세요,{' '}
            <Button onClick={onClickPasswordChange} className="font-semibold">
              {memberData.name}
            </Button>
            님
          </div>

          <Tooltip placement="bottom" title={'로그아웃'}>
            <Button className="mr-2 rounded-full" icon={<PoweroffOutlined />} onClick={onClickLogout}></Button>
          </Tooltip>
        </div>
      </Header>
      <Modal title="비밀번호 변경" open={isOpenModal} footer={null} onCancel={onCloseModal} centered>
        <div style={{ textAlign: 'center' }}>
          <div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>비밀번호:
              </span>
              <Input
                type="password"
                placeholder="비밀번호"
                name="password"
                value={password.password}
                onChange={onChangeInput}
              />
            </div>
            <div className="flex items-center justify-center p-2">
              <span className="w-28">
                <span className="text-[#FF0000] text-[10px] align-text-top mr-1">*</span>비밀번호 확인:{' '}
              </span>
              <Input
                type="password"
                placeholder="비밀번호 확인"
                name="confirmPassword"
                value={password.confirmPassword}
                onChange={onChangeInput}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <Button className="w-[90px]" onClick={() => onCloseModal()}>
              닫기
            </Button>
            <Button type="primary" onClick={onClickChangePassword} className="w-[90px] bg-[#1890ff] mx-2">
              완료
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HeaderComponent;
