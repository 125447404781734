import { notificationEnumAtom } from '@/store/notification';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import React from 'react';
const { RangePicker } = DatePicker;
interface SearchProps {
  selectedFilterValue: SelectedNotificationFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedNotificationFilterValue>>;
  getNotificationData: () => void;
}

const NotificationFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getNotificationData,
}: SearchProps) => {
  const [notificationFilterInfo, setNotificationFilterInfo] = useAtom(notificationEnumAtom);

  const convertNotificationFilterInfoToSelectOptions = (notificationFilterInfo: NotificationFilterInfoType) => {
    return {
      notificationType: convertToSelectOptions(notificationFilterInfo.notificationType),
      isSend: [
        { label: '전체', value: null },
        { label: '전송', value: 'true' },
        { label: '미전송', value: 'false' },
      ],
    };
  };

  const selectOptions = convertNotificationFilterInfoToSelectOptions(notificationFilterInfo);
  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    const [startDate, endDate] = dateStrings;

    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      startDate,
      endDate,
    }));
  };

  const handleInputStringChange = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      notificationType: null,
      isSend: null,
      reservationStartDate: null,
      reservationEndDate: null,
      ascending: false,
    });
  };
  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="알림 타입"
          optionFilterProp="children"
          options={selectOptions.notificationType}
          value={selectedFilterValue.notificationType}
          onChange={(value) => onChangeSelect('notificationType', value)}
        />
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="전송여부"
          optionFilterProp="children"
          options={selectOptions.isSend}
          value={selectedFilterValue.isSend}
          onChange={(value) => onChangeSelect('isSend', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <div className="w-full">
          <span className="mr-2">예약일자</span>
          <RangePicker
            className="min-w-[400px]"
            value={[
              selectedFilterValue.reservationStartDate ? dayjs(selectedFilterValue.reservationStartDate) : null,
              selectedFilterValue.reservationEndDate ? dayjs(selectedFilterValue.reservationEndDate) : null,
            ]}
            onChange={handleDateRangeChange}
            placeholder={['시작 날짜', '종료 날짜']}
          />
        </div>
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getNotificationData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default NotificationFilterSelector;
