import { purchaseAccompanyingServicesEnumAtom } from '@/store/purchaseAccompanyingServices';
import { convertToSelectOptions } from '@/utils/common';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import { useAtom } from 'jotai';
import React from 'react';

interface SearchProps {
  selectedFilterValue: SelectedPurchaseAccompanyingServicesFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<SelectedPurchaseAccompanyingServicesFilterValue>>;
  getPurchaseAccompanyingServicesData: () => void;
}

const PurchaseAccompanyingServicesFilterSelector = ({
  selectedFilterValue,
  setSelectedFilterValue,
  getPurchaseAccompanyingServicesData,
}: SearchProps) => {
  const [purchaseAccompanyingServicesFilterInfo, setPurchaseAccompanyingServicesFilterInfo] = useAtom(
    purchaseAccompanyingServicesEnumAtom,
  );

  const convertPurchaseAccompanyingServicesFilterInfoSelectOptions = (
    purchaseAccompanyingServicesFilterInfo: PurchaseAccompanyingServicesFilterInfoType,
  ) => {
    return {
      status: convertToSelectOptions(purchaseAccompanyingServicesFilterInfo.status),
    };
  };

  const selectOptions = convertPurchaseAccompanyingServicesFilterInfoSelectOptions(
    purchaseAccompanyingServicesFilterInfo,
  );

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      status: null,
    });
  };

  return (
    <>
      <div className="py-4">
        <div className="mb-4 flex items-center">
          <Select
            className="mr-4 my-2"
            style={{ width: 200 }}
            placeholder="상태"
            optionFilterProp="children"
            options={selectOptions.status}
            value={selectedFilterValue.status}
            onChange={(value) => onChangeSelect('status', value)}
          />
        </div>
      </div>

      <div className="w-full flex mb-2">
        <div className="w-full"></div>
        <Button type="primary" icon={<SearchOutlined />} onClick={getPurchaseAccompanyingServicesData} className="mr-2">
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </>
  );
};

export default PurchaseAccompanyingServicesFilterSelector;
