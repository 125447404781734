import { getDelayProductsList } from '@/api/public';
import DelayProductsFilterSelector from '@/components/Vehicles/Delay/DelayProductsFilterSelector';
import DelayProductsSearchResult from '@/components/Vehicles/Delay/DelayProductsSearchResult';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DelayProductsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilterValue, setSelectedFilterValue] = useState<DelayProductsSelectedFilterValue>({
    salesType: '',
  });
  const [searchResultData, setSearchResultData] = useState<DelayProductsPagingResponse | null>(null);

  const location = useLocation();
  const notAssuranceCount = location.state?.notAssuranceCount ? location.state.notAssuranceCount : 0;
  const assuranceCount = location.state?.assuranceCount ? location.state.assuranceCount : 0;

  const getDelayProductData = async (page?: number) => {
    const { salesType } = selectedFilterValue;

    const queryParams = new URLSearchParams({
      salesType: salesType,
      page: String(page ? page : 1),
      size: '10',
    });

    const response = await getDelayProductsList(queryParams);
    setSearchResultData(response.data);
  };

  useEffect(() => {
    getDelayProductData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilterValue]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <h3 className="text-gray-8">판매지연 리스트</h3>

      <DelayProductsFilterSelector
        selectedFilterValue={selectedFilterValue}
        setSelectedFilterValue={setSelectedFilterValue}
        getDelayProductData={() => getDelayProductData(currentPage)}
      ></DelayProductsFilterSelector>
      <DelayProductsSearchResult
        selectedFilterValue={selectedFilterValue}
        data={searchResultData}
        getDelayProductData={() => getDelayProductData(currentPage)}
        notAssuranceCount={notAssuranceCount}
        assuranceCount={assuranceCount}
      />
      <Pagination
        current={currentPage}
        total={searchResultData?.totalElements}
        defaultPageSize={10}
        showSizeChanger={false}
        onChange={handlePaginationChange}
        style={{ width: '100%', textAlign: 'right' }}
      />
    </>
  );
};

export default DelayProductsList;
