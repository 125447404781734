import { Table, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react';

interface DataType {
  id: number;
  productNumber: string;
  truckName: string;
  truckNumber: string;
  isCompleted: boolean;
  sellerName: string;
  buyerName: string;
  price: number;
  createdDate: string;
  transfereeCompletedDate?: string;
}

const ContractsSearchResult = ({
  selectedFilterValue,
  data,
  getContractsData,
  completedContractCount,
  notCompletedCount,
}: ContractsResponseDataProps) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: '제시번호',
      dataIndex: 'productNumber',
    },
    {
      title: '차량명',
      dataIndex: 'truckName',
    },
    {
      title: '차량번호',
      dataIndex: 'truckNumber',
    },
    {
      title: '구분',
      dataIndex: 'isCompleted',
      render: (text, record) => (record.isCompleted ? '체결완료' : '체결중'),
    },
    {
      title: '판매자',
      dataIndex: 'sellerName',
    },
    {
      title: '구매자',
      dataIndex: 'buyerName',
    },
    {
      title: '매매가',
      dataIndex: 'price',
    },
    {
      title: '등록일자',
      dataIndex: 'createdDate',
    },
    {
      title: '체결일자',
      dataIndex: 'transfereeCompletedDate',
    },
  ];

  const updatedTableData =
    data?.data?.map((item) => ({
      id: item.id,
      key: item.id,
      productNumber: item.productNumber,
      truckName: item.truckName,
      truckNumber: item.truckNumber,
      isCompleted: item.isCompleted,
      sellerName: item.sellerName,
      buyerName: item.buyerName,
      price: item.price,
      createdDate: item.createdDate,
      transfereeCompletedDate: item.transfereeCompletedDate,
    })) || [];
  const [tableData, setTableData] = useState<DataType[]>(updatedTableData);

  useEffect(() => {
    setTableData(updatedTableData);
  }, [data]);

  return (
    <>
      <div className="py-4">
        <div className="flex justify-between" style={{ marginBottom: 16 }}>
          <div>
            <span className="font-bold">체결완료</span> {completedContractCount}명
            <span className="font-bold ml-2">체결중</span> {notCompletedCount}명
          </div>
        </div>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </>
  );
};

export default ContractsSearchResult;
