import { Button } from 'antd';
import React from 'react';

interface SortingButtonProps {
  title: string;
  isAscending?: boolean;
  onclick: () => void;
}

const SortingButton = ({ title, isAscending, onclick }: SortingButtonProps) => {
  return (
    <div>
      <span className="pr-2">{title}</span>

      {isAscending ? (
        <Button size="small" onClick={onclick}>
          ↑
        </Button>
      ) : (
        <Button size="small" onClick={onclick}>
          ↓
        </Button>
      )}
    </div>
  );
};

export default SortingButton;
