import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import React, { useEffect, useMemo, useState } from 'react';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';

interface DragAndDropWrapperProps {
  children: React.ReactNode;
  productImagesList: ProductImage[];
  setProductImagesList: React.Dispatch<React.SetStateAction<ProductImage[]>>;
  setIsDisabledEditBtn: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}

const DragAndDropWrapper = ({
  children,
  productImagesList,
  setProductImagesList,
  setIsDisabledEditBtn,
  isDisabled,
}: DragAndDropWrapperProps) => {
  const [items, setItems] = useState<ProductImage[]>(productImagesList);

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const handleDragEnd = ({ active, over }: any) => {
    if (isDisabled) return;
    if (active.id !== over?.id) {
      setProductImagesList((prev) => {
        const activeIndex = prev.findIndex((i) => i?.id === active?.id);
        const overIndex = prev.findIndex((i) => i?.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
      setIsDisabledEditBtn(false);
    }
  };

  return (
    <DndContext sensors={[sensor]} onDragEnd={handleDragEnd}>
      <SortableContext items={items.map((i) => i.id)} strategy={verticalListSortingStrategy} disabled={isDisabled}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default DragAndDropWrapper;
