import { convertToSelectOptions } from '@/utils/common';
import { Button, Col, Divider, Input, Row, Select, message } from 'antd';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getBannersDetail, patchBanners, postBanners } from '@/api/public';
import FileUploader from '@/components/Common/FileUploader';
import { HTML, IMAGE } from '@/const/banner';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { bannerEnumAtom } from '@/store/banner';
import { useAtom } from 'jotai';
import { useMutation, useQuery } from 'react-query';

const { TextArea } = Input;

function BannerForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const labelStyle: React.CSSProperties = { padding: '8px 0', fontWeight: '700' };
  const style: React.CSSProperties = { padding: '8px 0' };
  const [bannerFilterInfo] = useAtom(bannerEnumAtom);

  const [isDisabledEditBtn, setIsDisabledEditBtn] = useState(true);
  const [prevData, setPrevData] = useState<SelectedBannerValue>({
    contents: '',
    contentsType: null,
    fileName: '',
    display: null,
    tracking: false,
    link: '',
    type: null,
    bannerLocation: null,
  });
  const [selectedValue, setSelectedValue] = useState<SelectedBannerValue>({
    contents: '',
    contentsType: null,
    fileName: '',
    display: null,
    tracking: false,
    link: '',
    type: null,
    bannerLocation: null,
  });

  const [bannerImage, setBannerImage] = useState({
    index: 0,
    id: 'bannerImage',
    name: '배너 이미지',
    value: '',
  });

  const convertBannerFilterInfoToSelectOptions = (bannerFilterInfo: BannerFilterInfoType) => {
    return {
      bannersType: convertToSelectOptions(bannerFilterInfo.bannersType),
      bannerLocation: convertToSelectOptions(bannerFilterInfo.bannerLocation),
      contentsType: [
        { value: 'IMAGE', label: '이미지' },
        { value: 'HTML', label: 'HTML' },
      ],
      display: [
        { value: true, label: '노출' },
        { value: false, label: '미노출' },
      ],
    };
  };

  const selectOptions = convertBannerFilterInfoToSelectOptions(bannerFilterInfo);

  const onChangeSelect = (name: string, value: string | boolean) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const onChangeInput = (name: string, value: string | boolean) => {
    setIsDisabledEditBtn(false);
    setSelectedValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const postBannerMutation = useMutation((requestData: SelectedBannerValue) => postBanners(requestData), {
    onSuccess: () => {
      message.success('배너가 등록되었습니다.', 2);
      setIsDisabledEditBtn(true);
    },
    onError: (error: AxiosError) => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const onClickRegister = () => {
    const { contents, contentsType, fileName, display, link, type, tracking, bannerLocation } = selectedValue;
    const request = {
      contents: contentsType === IMAGE ? bannerImage.value : contents,
      contentsType,
      fileName,
      display,
      link,
      type,
      tracking,
      bannerLocation,
    };
    postBannerMutation.mutate(request);
  };

  const getBannersDetails = async () => {
    try {
      const response = await getBannersDetail(id);
      const res = response.data;
      const { contents, contentsType, fileName, display, tracking, link, type, bannerLocation } = res;
      if (contentsType.code === IMAGE) {
        setSelectedValue({
          ...selectedValue,
          contents: '',
          contentsType: contentsType.code,
          fileName,
          display,
          tracking,
          link,
          type: type.code,
          bannerLocation: bannerLocation.code,
        });
        setPrevData({
          ...selectedValue,
          contents: '',
          contentsType: contentsType.code,
          fileName,
          display,
          tracking,
          link,
          type: type.code,
          bannerLocation: bannerLocation.code,
        });
        setBannerImage({
          index: 0,
          id: 'bannerImage',
          name: '배너 이미지',
          value: contents,
        });
      } else if (contentsType.code === HTML) {
        setSelectedValue({
          ...selectedValue,
          contents,
          contentsType: contentsType.code,
          fileName,
          display,
          tracking,
          link,
          type: type.code,
          bannerLocation: bannerLocation.code,
        });
        setPrevData({
          ...selectedValue,
          contents: '',
          contentsType: contentsType.code,
          fileName,
          display,
          tracking,
          link,
          type: type.code,
          bannerLocation: bannerLocation.code,
        });
      }
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const getBannerDetailQuery = useQuery(['get-banner-detail'], getBannersDetails, {
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
  });

  useEffect(() => {
    if (id) {
      getBannerDetailQuery.refetch();
    }
  }, [id]);

  const onClickGoToList = () => {
    navigate('/banner');
  };

  const patchBannerMutation = useMutation((requestData: SelectedBannerValue) => patchBanners(String(id), requestData), {
    onSuccess: (data) => {
      message.success('배너가 수정되었습니다.', 2);
      setIsDisabledEditBtn(true);
      const { contents, contentsType, fileName, display, tracking, link, type, bannerLocation } = data.data;
      setPrevData({
        ...selectedValue,
        contents,
        contentsType: contentsType.code,
        fileName,
        display,
        tracking,
        link,
        type: type.code,
        bannerLocation: bannerLocation.code,
      });
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const onClickUpdate = () => {
    const request: any = {};

    if (prevData?.type !== selectedValue.type) {
      request.type = selectedValue.type;
    }
    if (prevData?.contentsType !== selectedValue.contentsType) {
      request.contentsType = selectedValue.contentsType;
    }
    if (selectedValue.contentsType === HTML && prevData?.contents !== selectedValue.contents) {
      request.contents = selectedValue.contents;
    }

    if (selectedValue.contentsType === IMAGE && prevData?.contents !== bannerImage.value) {
      request.contents = bannerImage.value;
    }
    if (prevData?.fileName !== selectedValue.fileName) {
      request.fileName = selectedValue.fileName;
    }
    if (prevData?.link !== selectedValue.link) {
      request.link = selectedValue.link;
    }
    if (prevData?.display !== selectedValue.display) {
      request.display = selectedValue.display;
    }
    if (prevData?.bannerLocation !== selectedValue.bannerLocation) {
      request.bannerLocation = selectedValue.bannerLocation;
    }
    patchBannerMutation.mutate(request);
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="flex items-center">
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>배너타입</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4 my-2"
              style={{ width: 200 }}
              placeholder="배너 타입"
              optionFilterProp="children"
              options={selectOptions.bannersType}
              value={selectedValue?.type}
              onChange={(value) => onChangeSelect('type', value)}
            />
          </div>
          <div className="text-red-500 text-[13px] font-bold">
            내부용 : 서비스 내 화면 이동<br></br>
            외부용 : 외부 링크로 이동
          </div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>배너 노출 위치</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4 my-2"
              style={{ width: 200 }}
              placeholder="배너 노출 위치"
              optionFilterProp="children"
              options={selectOptions.bannerLocation}
              value={selectedValue?.bannerLocation}
              onChange={(value) => onChangeSelect('bannerLocation', value)}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>배너 내용 타입</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Select
              className="mr-4 my-2"
              style={{ width: 200 }}
              placeholder="배너 타입"
              optionFilterProp="children"
              options={selectOptions.contentsType}
              value={selectedValue?.contentsType}
              onChange={(value) => onChangeSelect('contentsType', value)}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="flex items-center">
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>배너 내용</div>
        </Col>
        <Col className="gutter-row" span={20}>
          <div style={style}>
            <TextArea
              value={selectedValue.contents || ''}
              onChange={(e) => onChangeInput('contents', e.target.value)}
              placeholder="배너 내용 입력"
              autoSize={{ minRows: 8, maxRows: 8 }}
              disabled={selectedValue.contentsType === IMAGE}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="flex items-center">
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>배너 내용</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <FileUploader
              type="banner"
              isShowImage={true}
              imageType="BANNER_IMAGE"
              fileData={bannerImage}
              setFileData={setBannerImage}
              fileNameMaxLength={340}
              isDisabled={selectedValue.contentsType === HTML}
              setIsDisabledEditBtn={setIsDisabledEditBtn}
            ></FileUploader>
          </div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>파일명</div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="파일명"
              style={{ width: '100%', minWidth: 200 }}
              value={selectedValue?.fileName || ''}
              onChange={(e) => onChangeInput('fileName', e.target.value)}
              disabled={selectedValue.contentsType === HTML}
            />
          </div>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="flex items-center">
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>연결 링크</div>
        </Col>
        <Col className="gutter-row" span={5}>
          <div style={style}>
            <Input
              className="mr-1"
              placeholder="연결 링크"
              style={{ width: '100%', minWidth: 200 }}
              value={selectedValue?.link || ''}
              onChange={(e) => onChangeInput('link', e.target.value)}
            />
            <div className="text-red-500 pt-4 text-[13px] font-bold">
              배너 타입이 내부용인 경우, 이동을 원하는 url에서 <br></br>
              도메인을 제외한 뒤의 path만 넣어주세요.<br></br>
              예시 : /license (번호판 목록으로 이동을 원하는 경우)
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={labelStyle}>노출여부</div>
        </Col>
        <Col className="gutter-row" span={8}>
          <div style={style}>
            <Select
              className="mr-4 my-2"
              style={{ width: 200 }}
              placeholder="노출 여부"
              optionFilterProp="children"
              options={selectOptions.display}
              value={selectedValue?.display}
              onChange={(value) => onChangeSelect('display', value)}
            />
          </div>
        </Col>
      </Row>

      <Divider orientation="left"></Divider>

      {id ? (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button
            type="primary"
            className="bg-[#1890ff] mx-2 w-20"
            onClick={onClickUpdate}
            disabled={isDisabledEditBtn}
          >
            수정하기
          </Button>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button
            type="primary"
            className="bg-[#1890ff] mx-2 w-20"
            onClick={onClickRegister}
            disabled={isDisabledEditBtn}
          >
            등록하기
          </Button>
        </div>
      )}
    </>
  );
}

export default BannerForm;
