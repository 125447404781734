// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* globalStyles.css */
* {
  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;

  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.ant-btn-primary:not([disabled]) {
  background-color: #1890ff !important;
  background-image: none;
}
`, "",{"version":3,"sources":["webpack://./src/css/globalStyle.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE;;iCAE+B;;EAE/B,mDAAmD;AACrD;;AAEA;EACE,oCAAoC;EACpC,sBAAsB;AACxB","sourcesContent":["/* globalStyles.css */\n* {\n  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',\n    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',\n    'Segoe UI Symbol', sans-serif;\n\n  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);\n}\n\n.ant-btn-primary:not([disabled]) {\n  background-color: #1890ff !important;\n  background-image: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
