import { SearchOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React from 'react';

interface SearchProps {
  selectedFilterValue: ContractSelectedFilterValue;
  setSelectedFilterValue: React.Dispatch<React.SetStateAction<ContractSelectedFilterValue>>;
  getContractsData: () => void;
}

const ContractsFilterSelector = ({ selectedFilterValue, setSelectedFilterValue, getContractsData }: SearchProps) => {
  const convertContractsFilterInfoToSelectOptions = () => {
    return {
      isCompleted: [
        { value: 'true', label: '체결완료' },
        { value: 'false', label: '체결중' },
      ],
    };
  };

  const selectOptions = convertContractsFilterInfoToSelectOptions();

  const onChangeSelect = (name: string, value: string) => {
    setSelectedFilterValue((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const clear = () => {
    setSelectedFilterValue({
      isCompleted: '',
    });
  };

  return (
    <div className="py-4">
      <div className="mb-4 flex items-center">
        <Select
          className="mr-4 my-2"
          style={{ width: 200 }}
          placeholder="체결여부"
          optionFilterProp="children"
          options={selectOptions.isCompleted}
          value={selectedFilterValue?.isCompleted || null}
          onChange={(value) => onChangeSelect('isCompleted', value)}
        />
      </div>
      <div className="w-full flex mb-2">
        <Button type="primary" icon={<SearchOutlined />} className="bg-[#1890ff] mx-2" onClick={getContractsData}>
          검색
        </Button>
        <Button onClick={clear}>초기화</Button>
      </div>
    </div>
  );
};

export default ContractsFilterSelector;
