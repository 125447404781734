import { patchNotice, postNotice } from '@/api/public';
import { COMMON_TOAST_ERROR_MESSAGE } from '@/const/errorMessage';
import { Button, Col, Divider, Input, Row, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TinyMCEEditor from '../Common/TinyMCEEditor';

const NoticeForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.noticeData || {};

  const [contents, setContents] = useState('');
  const [title, setTitle] = useState('');

  const [prevData, setPrevData] = useState({
    title: data.title,
    contents: data.contents,
  });

  useEffect(() => {
    if (id && data) {
      setTitle(data.title || '');
      setContents(data.contents || '');
    }
  }, [id]);

  const postNoticeMutation = useMutation(postNotice, {
    onSuccess: () => {
      message.success('공지사항이 등록되었습니다.', 2);
      onClickGoToList();
    },
    onError: () => {
      message.error(COMMON_TOAST_ERROR_MESSAGE, 2);
    },
  });

  const patchNoticeMutation = useMutation((requestData) => patchNotice(String(id), requestData), {
    onSuccess: () => {
      onClickGoToList();
    },
    onError: () => {},
  });

  const onClickUpdate = () => {
    const request: any = {};

    if (prevData?.title !== title) {
      request.title = title;
    }
    if (prevData?.contents !== contents) {
      request.contents = contents;
    }

    patchNoticeMutation.mutate(request);
  };

  const onClickRegister = () => {
    if (!title || !contents) {
      message.error('제목과 내용을 입력해주세요.', 2);
      return;
    }
    postNoticeMutation.mutate({ title, contents });
  };

  const onClickGoToList = () => {
    navigate('/notice');
  };

  const handleChange = (value: string) => {
    setContents(value);
  };

  return (
    <>
      <Row gutter={16} className="flex items-center">
        <Col span={3}>
          <div style={{ padding: '8px 0', fontWeight: '700' }}>공지사항 제목</div>
        </Col>
        <Col span={21}>
          <Input placeholder="공지사항 제목 입력" value={title} onChange={(e) => setTitle(e.target.value)} />
        </Col>
      </Row>

      <Row gutter={16} className="flex items-center">
        <Col span={3}>
          <div style={{ padding: '8px 0', fontWeight: '700' }}>공지사항 내용</div>
        </Col>
        <Col span={21}>
          <TinyMCEEditor value={contents} onChange={handleChange}></TinyMCEEditor>
        </Col>
      </Row>

      <Divider />

      {id ? (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button type="primary" className="bg-[#1890ff] mx-2 w-20" onClick={onClickUpdate}>
            수정하기
          </Button>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button onClick={onClickGoToList}>취소</Button>
          <Button type="primary" onClick={onClickRegister}>
            등록하기
          </Button>
        </div>
      )}
    </>
  );
};

export default NoticeForm;
